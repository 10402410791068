
import React from 'react';
import { FaThumbsUp } from 'react-icons/fa'; 
import MobileModel from '../Modals/MobileModel';
import {EditProfileLoader} from '../loader/loader';
const WaitingScreen = ({ nextScreen,loader,setLoader, }) => {
    const modleBody = (
        <>
          <div className="flex flex-col items-center gap-4">
            <div className="w-40 h-40 rounded-full bg-blue-50 flex justify-center items-center">
              <FaThumbsUp className="text-6xl text-accent" />
            </div>
            <div>
              <h1 className="text-3xl font-medium text-coalBlack text-center mb-4">
                Thanks
              </h1>
              <p className="text-gray-400 max-w-xs mx-auto text-sm font-normal text-center">
              Hospital details have been updated successfully
              </p>
            </div>
          </div>
        </>
      );
      const modelFooter = (
        <>
          <div className="mt-12 z-50">
            <button
              className="btn btn_primary_black text-white w-full rounded-xl"
              type="button"
              onClick={() => nextScreen()}
            >
              {!loader ? (
                'Done'
              ) : (
                <div className="text-xs">
                  <EditProfileLoader />
                </div>
              )}
            </button>
          </div>
        </>
      );
      return (
    <>
    <div className="md:hidden block">
      <MobileModel
        MobileModelBody={modleBody}
        MobileModelFooter={modelFooter}
      />
    </div>
    <div className=" absolute top-0 left-0 h-full w-full bg-white hidden md:flex">
      <div className="flex flex-col m-auto">
        {modleBody}
        {modelFooter}
      </div>
    </div>
  </>
      );
  
      
    };
    
    export default WaitingScreen;
