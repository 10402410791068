import React, { useState } from 'react';
import { PiStethoscopeFill } from 'react-icons/pi';
import { FaBriefcaseMedical } from 'react-icons/fa';
import { IoVideocam } from 'react-icons/io5';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import { DeleteconsultationAPI } from '../../apis';
import toast from 'react-hot-toast';
import { EditProfileLoader } from '../common/loader';
import SimpleModal from '../Modals/SimpleModal';
import { Trash, Trash2Fill } from 'react-bootstrap-icons';
import ConfirmPopup from '../confirmPopup/ConfirmPopup';
import Avatar from '../Avatar/Avatar';
import { FiInfo } from 'react-icons/fi';
import { MdWorkspacePremium } from "react-icons/md";
function ConsultationCard({
  data,
  editRequest,
  createRequest,
  ViewRequest,
  onload,
  fillBtnTitle,
  fillBtnfun,
  unFillBtnTitle,
  unFillBtnfun,
  showDeleteBtn,
}) {
  const [loader, setLoader] = useState(false);
  // alert(JSON.stringify(data))
  function getConsultationRequestStyle() {
    const style = {
      urgent: 'bg-red-200 text-red-500',
      regular: 'bg-green-200 text-green-500',
    };
    return style[data?.priority];
  }
  function gettypeText() {
    const text = {
      inperson: 'In-Person',
      Virtual: 'Virtual',
    };
    return text[data?.consType];
  }
  const [deletepopup, setdeletepopup] = useState(false);
  const deleteconsultation = async () => {
    try {
      setLoader(true);
      const res = await DeleteconsultationAPI(data?._id);
      if (res.status === 200) {
        toast.success(res.data.message);
        onload();
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
      const message =
        error?.response?.data?.message || error?.response?.statusText;
      toast.error(message, { id: 'error' });
    } finally {
      setLoader(false);
      setdeletepopup(false);
    }
  };
  let ispage = true;

  return (
    <div className="consultationCard bg-white space-y-4 shadow-card_shadow px-2 py-6 rounded-2xl md:px-3 flex flex-col justify-end">
      <div className="cardHead space-y-2">
          <div className='flex justify-end text-[30px] text-[#ffd700] h-[35px]'>
          {
          data?.method === 'private' &&
            <MdWorkspacePremium  />
          }
            </div>
        
       
        {data?.method === 'private' && data?.selecteddoctor.length == 0 && (
          <div className="flex  gap-x-1">
            <FiInfo className="text-base text-red-500" />
            <p className="text-[11px] leading-3 font-normal text-red-500 mt-0.5">
              Action required: The doctor declined the consultation.{' '}
            </p>
          </div>
        )}
        <div className="flex  items-center md:gap-2 px-2 py-3 md:p-3">
          <div className="inline-flex items-center gap-2 max-w-[60%]">
            {data?.hospitalId?.profilePic ? (
              <img
                className="size-10 min-w-10 bg-white rounded-full border border-white object-cover"
                src={`${process.env.REACT_APP_API_URL}/${data?.hospitalId?.profilePic}`}
                
              />
            ) : (
              <Avatar
                name={data?.hospitalId?.hospitalName}
                size="40"
                round={true}
              />
            )}

            <p
              className="subHeadingText text-coalBlack capitalize   line-clamp-1 "
              title={data?.hospitalId?.hospitalName}
            >
              {data?.hospitalId?.hospitalName}
            </p>
          </div>
          <div className="px-4 py-2 inline-block bg-sky-100 text-accent baseText rounded-full whitespace-nowrap capitalize user-select-none min-w-24 text-center ms-auto">
            {gettypeText()}
          </div>
        </div>

        <div
          className={
            'bg-[#F5FAFF] px-2 py-3 md:p-3 rounded-xl flex items-start justify-between min-h-14-'
          }
        >
          <p className="baseText text-navLink max-w-[80%] line-clamp-2">
            {data?.RequestDescription}
          </p>

          <div
            className={`px-4 py-2 inline-block baseText rounded-full capitalize user-select-none min-w-24 text-center ${getConsultationRequestStyle()} whitespace-nowrap`}
          >
            {data?.priority}
          </div>
        </div>
      </div>

      <div className={'cardFooter items-center flex gap-x-4'}>
        <SimpleButton
          title={fillBtnTitle}
          buttonType={'primary'}
          customClass={'rounded-full w-full'}
          onClickEvent={fillBtnfun}
        />
        {data?.Consultationstatus == 'pending'
          ? showDeleteBtn && (
            <SimpleButton
              title={unFillBtnTitle}
              buttonType={'primaryUnfilled'}
              customClass={'rounded-full w-full'}
              onClickEvent={() => {
                unFillBtnTitle === 'Delete' ? setdeletepopup(true) : '';
              }}
            />
          )
          : null}
      </div>

      {deletepopup && (
        <ConfirmPopup
          message={
            'Are you sure you want to delete this item? This action cannot be undone.'
          }
          handelConfirmPopUp={() => {
            setdeletepopup(false);
          }}
          popupStatus={deletepopup}
          confirmtext={
            loader ? (
              <div className="text-xs text-center">
                <EditProfileLoader />
              </div>
            ) : (
              'Delete'
            )
          }
          onclickFunction={deleteconsultation}
          canceltext={'Cancel'}
        />
        // <SimpleModal
        //   noScroll={true}
        //   closeModal={() => {
        //     setdeletepopup(false);
        //   }}
        // >
        //   <div className="row">
        //     <div className="bg-[#f5d9d7] p-2 shadow h-12 w-12 flex items-center justify-center rounded-lg ml-auto mr-auto text-center mt-2 mb-2">
        //       <Trash color="#D92D20" size={23} />
        //     </div>
        //     <label className="text-center font-semibold text-black text-lg capitalize mb-3">
        //       Delete Consultation
        //     </label>
        //     <div>
        //       <label
        //         className="form-label"
        //         style={{ textAlign: 'center', width: '100%' }}
        //       >
        //         Are you sure you want to delete this item? This action cannot be
        //         undone.
        //       </label>
        //     </div>
        //     <div className="brief-button-container">
        //       <button
        //         type="button"
        //         className="button-close"
        //         style={{ width: '100%' }}
        //         onClick={() => {
        //           setdeletepopup(false);
        //         }}
        //       >
        //         Cancel
        //       </button>
        //       <button
        //         onClick={deleteconsultation}
        //         className="button-warning "
        //         style={{ width: '100%' }}
        //       >
        //         {loader ? (
        //           <div className="text-xs text-center">
        //             <EditProfileLoader />
        //           </div>
        //         ) : (
        //           'Delete'
        //         )}
        //       </button>
        //     </div>
        //   </div>
        // </SimpleModal>
      )}
    </div>
  );
}

ConsultationCard.defaultProps = {
  fillBtnTitle: 'View',
  unFillBtnTitle: 'Delete',
  showDeleteBtn: true,
};

export default ConsultationCard;
