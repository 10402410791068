import React, { useEffect, useRef, useState, useContext } from 'react';
import AdminLayout from '../../components/admin-layout';
import UserProfile from '../../components/UserProfile/UserProfile';
import { FiLogOut } from 'react-icons/fi';
import { GoArrowUpRight } from 'react-icons/go';
import SideMessageNotification from './SideMessageNotification';
import Chart from 'chart.js/auto';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ConsultationCard from '../../components/ConsultationCard/ConsultationCard';
import { Link } from 'react-router-dom';
import Createrequest from '../../components/ConsultationCard/Createrequest';
import ViewRequest from '../../components/ConsultationCard/Viewrequest';
import {
  getNotificationAPI,
  getProfilebyId,
  getRecentConsltationAPI,
  getUserApi,
} from '../../apis';
import { AppContext } from '../../appContext';
import ConsultationViewRequest from '../../components/ConsultationCard/ConsultationViewRequest';

function ConsultationHistory({
  consultationhistorylable,
  consultationhistoryremote,
  consultationhistoryinperson,
  consultationhistorysucc,
  consultationhistoryremotecount,
  consultationhistoryinpersoncount,
  consultationhistorysucccount,
}) {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    console.log(consultationhistorysucc);
    const myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: consultationhistorylable,
        datasets: [
          {
            label: 'Remote Consultation',
            backgroundColor: '#F6CF7D',
            data: consultationhistoryremote,
            barThickness: 10,
            borderRadius: 2,
          },
          {
            label: 'In-Person Consultation',
            backgroundColor: '#EE89DF',
            data: consultationhistoryinperson,
            barThickness: 10,
            borderRadius: 2,
          },
          {
            label: 'Successful Consultation',
            backgroundColor: '#B6B0FB',
            data: consultationhistorysucc,
            barThickness: 10,
            borderRadius: 2,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
            align: 'start',
            display: true,
            labels: {
              usePointStyle: true,
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            grid: {
              drawBorder: false,
              drawOnChartArea: false, 
            },
          },
          y: {
            stacked: true,
            beginAtZero: true,
            grid: {
              drawBorder: false,
              drawOnChartArea: false, 
            },
          },
        },
      },
    });

    return () => {
      myChart.destroy();
    };
  }, [consultationhistorylable]);
  const consultationDivContent = [
    {
      type: 'Remote ',
      dotColor: 'bg-yellow-300',
      count: consultationhistoryremotecount,
      percentage: '+14%',
      percentageColor: 'green',
    },
    {
      type: 'In-person ',
      dotColor: 'bg-pink-300',
      count: consultationhistoryinpersoncount,
      percentage: '-26%',
      percentageColor: 'red',
    },
    {
      type: 'Successful ',
      dotColor: 'bg-purple-300',
      count: consultationhistorysucccount,
      percentage: '+7.2%',
      percentageColor: 'green',
    },
  ];

  const consultationDiv = consultationDivContent.map((data, id) => {
    return (
      <div className="" key={id}>
        <div className="flex gap-2 items-end">
          <div
            className={`min-w-1.5 h-1.5 mb-[5px] rounded-full ${data.dotColor}`}
          ></div>
          <div className="flex flex-col">
            <p className="headingText text-coalBlack  min-w-8">{data.count}</p>
            <p className="smallText text-navLink capitalize whitespace-nowrap">
              {data.type}
            </p>
          </div>
        </div>
      </div>
    );
  });
  return (
    <div className="bg-white p-4 rounded-3xl  shadow-blue_dropshadow w-full">
      <p className="headingText text-coalBlack text-left ">
        Consultation History
      </p>
      <div className="grid grid-cols-1 gap-2 my-2">
        <div className="min-h-[206px] md:min-h-[275px] ">
          <canvas ref={chartRef} />
        </div>
        <div className="flex flex-wrap gap-4">{consultationDiv}</div>
      </div>
    </div>
  );
}

export default ConsultationHistory;
