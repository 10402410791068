import React, { useContext, useEffect, useState } from 'react';
import AdminLayout from '../../components/admin-layout';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import { BsCurrencyRupee } from 'react-icons/bs';
import { IoWallet } from 'react-icons/io5';
import { FiArrowRight } from 'react-icons/fi';
import { RiCashLine } from 'react-icons/ri';
import { FaCreditCard } from 'react-icons/fa';
import CreditUsage from '../../components/wallets/CreditUsage';
import { FiChevronLeft } from 'react-icons/fi';
import Subscription from '../../components/wallets/Subscription';
import MainOddsPopup from '../../components/common/oddsPopup/MainOddsPopup';
import { AppContext, walletContext } from '../../appContext';

import {
  getCreditBasePrice,
  getCreditusedHisoryApi,
  getHospitalProfilebyId,
  getMonthlyDue,
  getProfilebyId,
  getcreditpurhcasegraph,
} from '../../apis';
import AmountDue from './AmountDue';
import MonthlyEarnings from './MonthlyEarnings';
import PayBill from '../../components/wallets/PayBill';
function WalletIndex() {
  const { loggedInUser } = useContext(AppContext);
  // console.log(loggedInUser?.data?.hospitalProfile?.walletId?.balance);
  const [Loader, setLoader] = useState(false);
  const [balanceData, setbalanceData] = useState([]);
  const [balance, setbalance] = useState(0);
  const [monthlyDueData, setMonthlyDueData] = useState(null);
  const [consultationHistoryBlock, setConsultationHistoryBlock] = useState(
    false
  );
  console.log(balance);
  const getItem = async () => {
    try {
      const res = await getHospitalProfilebyId(
        loggedInUser?.data?.hospitalProfile?._id
      );
      if (res.status === 200) {
        console.log(res.data.hospitalProfile);
        setbalance(res?.data?.hospitalProfile?.walletId?.balance);
        console.log(res?.data?.hospitalProfile?.walletId?.balance);
        try {
          const res = await getcreditpurhcasegraph(
            loggedInUser?.data?.hospitalProfile?._id
          );
          if (res.status === 200) {
            console.log(res.data.CreditPurchasedHistory);
            setbalanceData(res.data.CreditPurchasedHistory);
          }
        } catch (error) {
          setLoader(false);
          console.log(error);
        }
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };
  useEffect(() => {
    if (loggedInUser?.data?.hospitalProfile?._id) {
      getItem();
    }
  }, [loggedInUser]);
  const accountCardBody = (
    <div>
      <p className="text-coalBlack font-medium py-2">choose account</p>
      <div className="my-4 grid grid-cols-1 gap-6 text-coalBlack">
        <div className="bg-lightBlue p-4 rounded-2xl ">
          <div className="inline-flex gap-3">
            <div>
              <FaCreditCard className="text-2xl" />
            </div>
            <div>
              <p className="font-medium">ICICI Debit card</p>
              <p className="font-medium text-sm text-navLink">0318-1608-2105</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  const [subscriptionBlock, setSubscriptionBlock] = useState(false);
  const [creditUsageBlock, setCreditUsageBlock] = useState(false);
  const [buyCreditBlock, setBuyCreditBlock] = useState(false);
  const handleBuyCreditBlock = () => {
    if (!buyCreditBlock) {
    }
    setBuyCreditBlock(!buyCreditBlock);
  };

  const toGetMonthlyDue = async (payload) => {
    try {
      const res = await getMonthlyDue(payload);
      if (res.status === 200) {
        console.log(res);
        setMonthlyDueData(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const dateObj = new Date();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    const payload = {
      hospitalId: loggedInUser?.data?.hospitalProfile?._id,
      month: month <= 9 ? '0' + month : month,
      year: year,
    };
    toGetMonthlyDue(payload);
  }, [loggedInUser]);
  function getDueMonth(prop) {
    const date = new Date();
    date.setMonth(prop - 1);
    return date.toLocaleString('en-US', { month: 'long' });
  }

  return (
    <walletContext.Provider value={[handleBuyCreditBlock]}>
      <AdminLayout>
        <section className=" md:p-4 xl:p-5 xl:pb-0">
          <div className="flex items-center rounded-lg md:bg-white p-4 xl:p-5 ">
            <p className="headingText text-coalBlack  capitalize">Payments</p>
            <div
              className="gap-2 items-center ms-auto bg-accent rounded-lg text-white min-h-11 px-6 py-2 md:inline-flex hidden"
              onClick={handleBuyCreditBlock}
            >
              <RiCashLine className="text-2xl" />
              <button className="baseText ">Pay Amount</button>
            </div>

            <div className="ms-auto md:hidden">
              <div className="bg-white p-1 rounded-xl border border-gray-300">
                <IoIosHelpCircleOutline className="text-2xl text-accent" />
              </div>
            </div>
          </div>
          {/* sm screen  start*/}
          <div className="lg:hidden">
            <section className="mt-4 p-4">
              <div className="bg-accent text-white p-4 rounded-xl   shadow-blue_dropshadow min-h-32 flex flex-col">
                <div className="pb-6 flex">
                  <div className="inline-flex gap-2 items-center">
                    <IoWallet className="text-2xl text-white  m-auto  p-px rounded-full " />
                    <span className="headingText">
                      {getDueMonth(
                        new Date().getMonth() + 1 <= 9
                          ? 0 + new Date().getMonth() + 1
                          : new Date().getMonth() + 1
                      )}{' '}
                      Consultations
                    </span>
                  </div>
                  <span className="ms-auto headingText me-2">
                    {monthlyDueData?.TransactionRecord?.length <= 9
                      ? '0' + monthlyDueData?.TransactionRecord?.length
                      : monthlyDueData?.TransactionRecord?.length}
                  </span>
                </div>
                <div className="flex items-center pt-5 mt-auto border-t border-t-px border-gray-300/75 relative">
                  <p className="highlightText ">
                    <BsCurrencyRupee className="me-px inline-block" />
                    {monthlyDueData?.TotalAmount || '0'}
                  </p>
                </div>
              </div>
            </section>
            <div className="">
              <AmountDue />
            </div>
            <section className="mt-4 p-4 py-2">
              <div
                className="flex items-center p-2  shadow-blue_dropshadow rounded-2xl"
                onClick={() => {
                  setConsultationHistoryBlock(!consultationHistoryBlock);
                }}
              >
                <h1 className="text-accent headingText capitalize">
                  Recent Consultations
                </h1>
                <div className="ms-auto size-9 rounded-full inline-flex justify-center items-center active:bg-blue-50">
                  <FiArrowRight className="text-2xl text-accent" />
                </div>
              </div>
            </section>

            <section className="mt-4 p-4 py-2">
              <div
                className="flex items-center p-2  shadow-blue_dropshadow rounded-2xl"
                onClick={() => {
                  setCreditUsageBlock(!creditUsageBlock);
                }}
              >
                <h1 className="text-accent headingText capitalize">
                  view payments
                </h1>
                <div className="ms-auto size-9 rounded-full inline-flex justify-center items-center active:bg-blue-50">
                  <FiArrowRight className="text-2xl text-accent" />
                </div>
              </div>
            </section>
           
            

            <div className="fixed bottom-20 right-4 z-30 md:hidden">
              <div
                className="inline-flex gap-2 items-center ms-auto bg-accent rounded-xl text-white min-h-14 px-6 py-2 "
                onClick={handleBuyCreditBlock}
              >
                <RiCashLine className="text-2xl" />
                <button className="baseText !font-medium">Pay Amount</button>
              </div>
            </div>
          </div>
          {/* sm screen  end*/}

          {/* large devices start */}
          <div className=" hidden lg:grid-cols-12 gap-6 lg:grid my-12 ">
            <div className="col-span-12  xl:col-span-8">
              <section className="py-5 bg-white p-4 rounded-2xl  gap-x-4">
                <p className="text-coalBlack  capitalize mb-3 headingText col-span-2">
                  overview
                </p>
                <div className="bg-accent text-white p-4 rounded-xl   shadow-blue_dropshadow min-h-32 flex flex-col">
                  <div className="pb-6 flex">
                    <div className="inline-flex gap-2 items-center">
                      <IoWallet className="text-2xl text-white  m-auto  p-px rounded-full " />
                      <span className="headingText  ms-1">
                        {getDueMonth(
                          new Date().getMonth() + 1 <= 9
                            ? 0 + new Date().getMonth() + 1
                            : new Date().getMonth() + 1
                        )}{' '}
                        Consultations
                      </span>
                    </div>
                    <span className="ms-auto headingText me-2">
                      {monthlyDueData?.TransactionRecord?.length <= 9
                        ? '0' + monthlyDueData?.TransactionRecord?.length
                        : monthlyDueData?.TransactionRecord?.length}
                    </span>
                  </div>
                  <div className="flex items-center pt-5 mt-auto border-t border-t-px border-gray-300/75 relative">
                    <p className="highlightText ">
                      <BsCurrencyRupee className="me-px inline-block" />
                      {monthlyDueData?.TotalAmount || '0'}
                    </p>
                  </div>
                </div>
              </section>
              

              <section className=" bg-white rounded-2xl mt-4">
                <CreditUsage />
              </section>
            </div>
            <div className="col-span-12 hidden  lg:block max-h-screen xl:col-span-4">
              <section className="h-full scrollbar overflow-y-scroll">
                <div className="h-fit">
                  <AmountDue />
                </div>
                <div className="bg-white rounded-2xl overflow-y-auto  scrollbar">
                  <h1 className="text-coalBlack subHeadingText p-4 py-5 sticky top-0 bg-white border-b border-gray-100">
                    Recent Consultations
                  </h1>
                  <MonthlyEarnings data={monthlyDueData} />
                </div>
              </section>
            </div>
          </div>
          {/* large devices end */}

          {/* select cards start */}
          {subscriptionBlock && (
            <Subscription closeFunction={setSubscriptionBlock} />
          )}
          {creditUsageBlock && (
            <div className="lg:hidden">
              <MainOddsPopup>
                <div className="min-h-screen h-full bg-white">
                  <CreditUsage closeFunction={setCreditUsageBlock} />
                </div>
              </MainOddsPopup>
            </div>
          )}
          {buyCreditBlock && (
            // <BuyCredits
            //   closeFunction={handleBuyCreditBlock}
            //   getItem={getItem}
            // />
            <PayBill closeFunction={handleBuyCreditBlock} />
          )}
          {/* select cards end */}
          {/* consultationHistoryBlock start  */}
          {consultationHistoryBlock && (
            <div className="lg:hidden">
              <MainOddsPopup>
                <div className="min-h-screen h-screen bg-white overflow-y-auto scrollbar overscroll-contain pb-24">
                  <header className="p-4 sticky top-0 bg-white z-40 border-b border-gray-100">
                    <div className="flex items-center">
                      <div className="inline-flex gap-1 items-center w-full">
                        <FiChevronLeft
                          className="text-2xl text-coalBlack w-9 h-9 p-1 rounded-full active:bg-sky-50 lg:hidden"
                          onClick={() => {
                            setConsultationHistoryBlock(
                              !consultationHistoryBlock
                            );
                          }}
                        />
                        <p className="headingText text-coalBlack align-self-center capitalize">
                          Recent Consultations
                        </p>
                      </div>
                    </div>
                  </header>
                  <MonthlyEarnings data={monthlyDueData} />
                </div>
              </MainOddsPopup>
            </div>
          )}
          {/* consultationHistoryBlock end */}
        </section>
      </AdminLayout>
    </walletContext.Provider>
  );
}

export default WalletIndex;
