import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter,HashRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
const { REACT_APP_GOOGLE_SIGN_IN_CLIENT_ID } = process.env;
const CLIENT_ID = REACT_APP_GOOGLE_SIGN_IN_CLIENT_ID ? REACT_APP_GOOGLE_SIGN_IN_CLIENT_ID : '1060612675117-6natsgt65te1fpov01hdpa18d676mc16.apps.googleusercontent.com';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
    <GoogleOAuthProvider clientId={CLIENT_ID}>
        <App />
    </GoogleOAuthProvider>
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
