import React, { useEffect, useRef, useState, useContext } from 'react';
import AdminLayout from '../../components/admin-layout';
import UserProfile from '../../components/UserProfile/UserProfile';
import { FiLogOut } from 'react-icons/fi';
import { GoArrowUpRight } from 'react-icons/go';
import SideMessageNotification from './SideMessageNotification';
import Chart from 'chart.js/auto';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ConsultationCard from '../../components/ConsultationCard/ConsultationCard';
import { Link } from 'react-router-dom';
import Createrequest from '../../components/ConsultationCard/Createrequest';
import ViewRequest from '../../components/ConsultationCard/Viewrequest';
import {
  getNotificationAPI,
  getProfilebyId,
  getRecentConsltationAPI,
  getUserApi,
} from '../../apis';
import { AppContext } from '../../appContext';
import ConsultationViewRequest from '../../components/ConsultationCard/ConsultationViewRequest';
function BookingsCard({
  imgUrl,
  bookingTitle,
  bookingCount,
  bookingPercentage,
  bookingFromDate,
}) {
  return (
    <div className="space-y-7 w-full bg-white p-4 rounded-3xl h-full shadow-blue_dropshadow  lg:min-h-max">
      <div className="flex items-center gap-4">
        <div className="w-16 h-16">
          <img src={imgUrl} className="w-full aspect-square" />
        </div>
        <p className="headingText text-coalBlack capitalize">
          {bookingTitle}
        </p>
      </div>
      <div className="flex gap-2 items-center">
        <p className="headingText text-coalBlack min-w-16 text-center">
          {bookingCount}
        </p>
        <p className="text-base font-medium text-green-500">
        
        </p>
      </div>
    </div>
  );
}

export default BookingsCard;
