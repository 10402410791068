import React, { useState, useContext, useEffect, useCallback } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { AiOutlineUser } from 'react-icons/ai';
import { IoVideocam } from 'react-icons/io5';
import { FaTags } from 'react-icons/fa';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import { InputErrorMessage } from '../errorMessages';
import { ConsultationRequest } from '../Validation';
import { HiOutlinePlus } from 'react-icons/hi2';
import { HiOutlineX } from 'react-icons/hi';
import { BiSolidReport } from 'react-icons/bi';
import { FaFilePrescription } from 'react-icons/fa';
import { EditProfileLoader } from '../common/loader';
import { GrNotes } from 'react-icons/gr';
import { FaLocationDot } from 'react-icons/fa6';
import { IoMdCall } from 'react-icons/io';
import { useDropzone } from 'react-dropzone';
import { CloudArrowUpFill, X } from 'react-bootstrap-icons';
import { fileUploadApi, getUserApi, getdoctorslistAPI, insertconsultationAPI, updateconsultationAPI } from '../../apis';
import Select from 'react-select';
import RecordSelector from './RecordsSelect';
import ProgressBar from 'react-bootstrap/esm/ProgressBar';

import { PiStethoscopeFill } from 'react-icons/pi';
import Tabs from './tabs/tabscontent';
function ViewRequest({ closeFunction, data, profileData, onload }) {

  function gettypeText() {
    const text = {
      inperson: 'In-Person',
      Virtual: 'Virtual',
    };
    return text[data?.consType];
  }
  function getConsultationRequestStyle() {
    const style = {
      urgent: 'bg-red-200 text-red-500',
      regular: 'bg-green-200 text-green-500',
    };
    return style[data?.priority];
  }
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const tabs = [
    {
      label: 'Report',
      content: (
        <div className="my-4">
          {data?.record?.Details.some(file => file.type === 'report') ? (
            <div className="grid grid-cols-3 gap-4">
              {data?.record?.Details.map((file, index) => (
                file.type === 'report' ? (
                  <div key={index}>
                    <div className="relative">
                      <div className="w-[100px] h-32 border border-gray-200 rounded-lg overflow-hidden">
                        <img src={file.filePath} className="w-full h-full object-cover" alt={file.fileName} />
                      </div>
                    </div>
                  </div>
                ) : null
              ))}
            </div>
          ) : (
            <div className='h-20 p-10 flex items-center justify-center'>
              <p className='text-md font-medium text-blue-300'>No Reports Attached</p>
            </div>
          )}
        </div>
      )
    },
    {
      label: 'Prescription',
      content: (
        <div className="my-4">
          {data?.record?.Details.some(file => file.type === 'prescription') ? (
            <div className="grid grid-cols-3 gap-4">
              {data?.record?.Details.map((file, index) => (
                file.type === 'prescription' ? (
                  <div key={index}>
                    <div className="relative">
                      <div className="w-[100px] h-32 border border-gray-200 rounded-lg overflow-hidden">
                        <img src={file.filePath} className="w-full h-full object-cover" alt={file.fileName} />
                      </div>
                    </div>
                  </div>
                ) : null
              ))}
            </div>
          ) : (
            <div className='h-20 p-10 flex items-center justify-center w-full'>
              <p className='text-md font-medium text-blue-300'>No Prescription Attached</p>
            </div>
          )}
        </div>
      )
    },
    {
      label: 'Notes',
      content: (
        <div className="my-4">
          {data?.record?.Details.some(file => file.type === 'notes') ? (
            <div className="grid grid-cols-3 gap-4">
              {data?.record?.Details.map((file, index) => (
                file.type === 'notes' ? (
                  <div key={index}>
                    <div className="relative">
                      <div className="w-[100px] h-32 border border-gray-200 rounded-lg overflow-hidden">
                        <img src={file.filePath} className="w-full h-full object-cover" alt={file.fileName} />
                      </div>
                    </div>
                  </div>
                ) : null
              ))}
            </div>
          ) : (
            <div className='h-20 p-10 flex items-center justify-center w-full'>
              <p className='text-md font-medium text-blue-300'>No Notes Attached</p>
            </div>
          )}
        </div>
      )
    }
  ];
  
  return (
    <section className="bg-black/25 fixed top-0 left-0 w-full h-screen z-50 flex justify-center items-center  lg:z-[10000]">
      <div
        className="absolute top-0 left-0 w-full h-full bg-inherit"
        onClick={closeFunction}
      ></div>
      <div className="bg-bodybackground relative z-20 m-auto overflow-y-auto scrollbar w-full max-h-screen lg:max-w-lg lg:py-4 lg:px-8 lg:max-h-[96vh]  lg:rounded-3xl">
        {/* LG Navigation  */}
        <div className="Navigation justify-between items-center hidden py-4 lg:flex ">
          <p
            className="text-gray-500 text-sm font-medium min-w-10 p-2 cursor-pointer w-full text-right"
            onClick={closeFunction}
          >
            Close
          </p>
        </div>
        {/* LG profile viewer  */}
        <div className="profileForLargeDevice rounded-2xl bg-white p-4 space-y-3 mb-4 hidden lg:block">
          <div>

            {
              profileData?.hospitalProfile?.coverPic ? <img className="h-36 rounded-2xl bg-white w-[100%]" src={profileData?.hospitalProfile?.coverPic} /> :
                <div className="bg-gray-100 h-36 rounded-2xl "></div>

            }
            {
              profileData?.hospitalProfile?.profilePic ? <img className="w-14 h-14 bg-white rounded-full relative top-[-30px] left-1/2 -translate-x-1/2 border-[3px] object-cover border-white" src={`${process.env.REACT_APP_API_URL}/${profileData?.hospitalProfile?.profilePic}`} /> :
                <div className="w-14 h-14 bg-gray-400 rounded-full relative top-[-30px] left-1/2 -translate-x-1/2 border-[3px]  border-white "></div>

            }

          </div>
          <div className="px-6 space-y-3">
            <p className="text-coalBlack text-center font-medium text-xl capitalize">
              {profileData?.hospitalProfile?.hospitalName}
            </p>

            <div className="flex items-start gap-2">
              <FaLocationDot className="text-xl text-accent min-w-4" />
              <p className="text-base font-medium text-gray-400 capitalize">
                {profileData?.hospitalProfile?.address}
              </p>
            </div>
            <div className="flex items-start gap-2">
              <IoMdCall className="text-xl text-accent min-w-4" />
              <p className="text-base font-medium text-gray-400 capitalize">
                {profileData?.hospitalProfile?.refCountryCode}{profileData?.hospitalProfile?.refPhoneNo}
              </p>
            </div>
          </div>
        </div>
        <main className="bg-white p-4 pt-0 pb-16  lg:rounded-2xl lg:py-5">
          {/* SM Navigation  */}
          <div className=" bg-white flex items-center gap-2 sticky top-0 py-2 z-30 lg:hidden">
            <FiChevronLeft
              className="text-2xl text-coalBlack w-9 h-9 p-1 rounded-full active:bg-sky-50"
              onClick={closeFunction}
            />

          </div>
          {/* SM profile viewer  */}
          <div className="profileForSmallDevice flex items-start gap-2 my-2 py-2 rounded-2xl shadow-blue_dropshadow lg:hidden">

            {
              profileData?.hospitalProfile?.profilePic ? <img className="min-w-10 h-10 bg-blue-400 rounded-full" src={`${process.env.REACT_APP_API_URL}/${profileData?.hospitalProfile?.profilePic}`} /> :
                <div className="min-w-10 h-10 bg-blue-400 rounded-full"></div>

            }
            <p className="text-sm font-normal text-[#333] max-w-[70%] capitalize">
              {profileData?.hospitalProfile?.hospitalName}
            </p>
          </div>

          <div className="flex  items-center md:gap-2 px-2 py-3 md:p-3">
            <div className="inline-flex items-center gap-2 " style={{ width: '38%' }}>
              <div className="w-6 h-6 bg-sky-100 rounded-full flex justify-center items-center p-1">
                <PiStethoscopeFill className="text-lg text-accent" />
              </div>
              <p
                className=" lg:block text-sm font-medium text-coalBlack capitalize"
                title="Orthopedic Surgeon Orthopedic Surgeon Orthopedic Surgeon"
              >
                {data?.doctorDetails?.Doctor?.[0].doctorName}
              </p>
            </div>
            <div className="px-4 py-2 inline-block bg-sky-100 text-accent text-sm font-normal rounded-full capitalize user-select-none min-w-24 text-center ml-auto mr-1">

              {gettypeText()}
            </div>
            <div className={`px-4 py-2 inline-block text-sm font-normal rounded-full capitalize user-select-none min-w-24 text-center ${getConsultationRequestStyle()}`}>
              {data?.priority}
            </div>
          </div>
          <div>
            <p className=" text-md font-medium text-coalBlack mt-2 mb-2 ml-2 ">
              Request Description
            </p>
            <div className="bg-[#F5FAFF] px-2 py-3 md:p-3 rounded-xl">

              <p className="text-sm font-normal text-gray-500">
                {data?.RequestDescription}
              </p>
            </div>
          </div>
          
          <div>
            <p className="text-md font-medium text-coalBlack mt-2 mb-2 ml-2 ">
              Doctors Description
            </p>
            <div className="bg-[#F5FAFF] px-2 py-3 md:p-3 rounded-xl">

              <p className="text-sm font-normal text-gray-500">
                {data?.doctorNotes}
              </p>
            </div>
          </div>
          <div>
            <p className="text-md font-medium text-coalBlack mt-2 mb-2 ml-2 ">
              Patient Details
            </p>
            <div className="bg-[#F5FAFF] px-2 py-3 md:p-3 rounded-xl">
              <p className='flex items-center gap-2 capitalize'><p className='font-medium text-black capitalize'>Gender: </p> <p>{data?.patientGender}</p></p>
              <p className='flex items-center gap-2'><p className='font-medium text-black'>Age: </p> <p>{data?.patientAge} years old</p></p>
            </div>
            <p className="text-md font-medium text-coalBlack mt-2 mb-2 ml-2 ">
              Medical Tags
            </p>
            <div className='my-2'>
              {data?.medicalTag?.map((tag, index) => (
                <div key={index} className="bg-[#1648CE] rounded-lg px-2 py-1 m-1 inline-block text-white ">
                  {tag}
                </div>
              ))}
            </div>
          </div>
          <div>
            <p className="text-md font-medium text-coalBlack mt-2 mb-2 ml-2 ">
          Attachments
            </p>
            <Tabs tabs={tabs} />
          </div>
        </main>
      </div>
    </section>
  );
}

export default ViewRequest;
