import React, { useContext, useState, useEffect } from 'react';
import TransitionCard from './TransitionCard';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import toast from 'react-hot-toast';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import BuyCredits from './BuyCredits';
import { walletContext, AppContext } from '../../appContext';
import { getCreditusedHisoryApi, getPaymentHistory } from '../../apis';
import PaginatedTable from '../TableOdds/PaginatedTable';
import { BsCurrencyRupee } from 'react-icons/bs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { IoIosArrowDown } from 'react-icons/io';
import { forwardRef } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { IoVideocam } from 'react-icons/io5';
import moment from 'moment';
function CreditUsage({ closeFunction }) {
  const { loggedInUser } = useContext(AppContext);
  const [Loader, setLoader] = useState(false);
  const [data, setData] = useState(null);
  const [mobileData, setMobileData] = useState(null);
  console.log(loggedInUser);

  const tableHeaders = [
    {
      name: 'Doctor Name',
      specialization: 'Specialization',
      type: 'Type',
      date: 'Completed Date',
      amount: 'Payment',
      status: 'Status',
    },
  ];

  const [startDate, setStartDate] = useState(new Date());
  // eslint-disable-next-line react/display-name
  const MonthCustomInput = forwardRef(({ value, onClick, className }, ref) => (
    <div
      className="bg-accent px-3 py-1 md:py-2 text-white subHeading rounded-lg flex gap-x-2 items-center cursor-pointer"
      onClick={onClick}
      ref={ref}
    >
      <button className={`${className} min-w-8 md:hidden`}>
        {value.substring(0, 3)}
      </button>
      <button className={`${className} min-w-8 md:block hidden`}>
        {value}
      </button>
      <IoIosArrowDown className="text-white " />
    </div>
  ));

  const toGetPaymentHistory = async (payload) => {
    try {
      const res = await getPaymentHistory(payload);
      if (res.status === 200) {
        const array = [];
        console.log(res?.data);
        setMobileData(res?.data?.data);
        res?.data?.data?.map((data) => {
          const obj = {
            name: (
              <p className="whitespace-nowrap line-clamp-1 baseText text-coalBlack capitalize">
                {data?.DoctorName[0]}
              </p>
            ),
            appointment: (
              <p className="whitespace-nowrap line-clamp-1 baseText text-coalBlack capitalize">
                {data?.Specalaization[0]}
              </p>
            ),
            sales: (
              <p className="whitespace-nowrap line-clamp-1 baseText text-coalBlack capitalize">
                {data?.ConsultationType[0]}
              </p>
            ),
            cDate: (
              <p className="whitespace-nowrap line-clamp-1 baseText text-coalBlack capitalize">
                {moment(data?.CompletedDate[0]).format('DD-MM-YYYY')}
              </p>
            ),
            payment: (
              <p className="whitespace-nowrap line-clamp-1 baseText text-coalBlack capitalize">
                {data?.AmountPaid}
              </p>
            ),
            status: (
              <p className="whitespace-nowrap line-clamp-1 baseText text-coalBlack capitalize">
                {data?.status}
              </p>
            ),
          };
          array.push(obj);
        });
        setData(array);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const dateObj = new Date(startDate);

    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();

    const payload = {
      hospitalId: loggedInUser?.data?.hospitalProfile?._id,
      month: month <= 9 ? '0' + month : month,
      year: year,
    };

    toGetPaymentHistory(payload);
  }, [startDate, loggedInUser]);
  console.log('mobile data', mobileData);
  
  return (
    <div className="p-3 mb-16 lg:mb-0">
      <section className="px-2 py-4 md:p-4 sticky top-0 bg-white z-40 border-b border-gray-400 mb-2">
        <div className="flex items-center gap-1">
          <div className="inline-flex gap-1 items-center w-full">
            <FiChevronLeft
              className="text-2xl text-coalBlack w-9 h-9 p-1 rounded-full active:bg-sky-50 lg:hidden"
              onClick={() => {
                closeFunction(false);
              }}
            />
            <p className="headingText text-coalBlack align-self-center whitespace-nowrap">
              Payment history
            </p>
          </div>
          <div className="md:ms-auto">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="MMMM"
              showMonthYearPicker
              showOneColumnMonthYearPicker
              calendarClassName="rasta-stripes left-[-88px] lg:left-auto"
              customInput={
                <MonthCustomInput className="example-custom-input" />
              }
            />
          </div>
        </div>
        {/* <div className="md:ms-auto ">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="MMMM"
            showMonthYearPicker
            showOneColumnMonthYearPicker
            calendarClassName="rasta-stripes -left-8"
           
            customInput={
              <MonthCustomInput className="example-custom-input" />
            }
          />
        </div> */}
      </section>

      <div className="hidden md:block">
        <PaginatedTable
          items={data}
          itemsPerPage={10}
          tableHeaders={tableHeaders}
        />
      </div>
      <div className="md:hidden pb-24 flex flex-col gap-3 py-2">
        {mobileData?.length == 0 || mobileData == undefined ? (
          <p className="baseText text-center mt-36 capitalize">
            No result found
          </p>
        ) : (
          mobileData?.map((consult) => (
            <div className="consultationCard bg-blue-50 shadow-card_shadow px-3 py-4 rounded-2xl md:px-3 gap-2 flex justify-between border border-[#1648ce80]">
              <div className="w-1/5 bg-accent flex justify-center items-center rounded-md">
                {consult.ConsultationType[0] == 'inperson' ? (
                  <AiOutlineUser className="text-4xl text-inherit text-white" />
                ) : (
                  <IoVideocam className="text-4xl text-inherit text-white" />
                )}
              </div>
              <div className="w-1/2 flex flex-col">
                <span className="text-coalBlack capitalize line-clamp-1 headingText font-bold">
                  {consult?.DoctorName[0]}
                </span>
                <span className="text-slate-500 capitalize line-clamp-1 subHeadingText">
                  {consult?.Specalaization[0]}
                </span>
                <span className="text-slate-500 capitalize line-clamp-1 subHeadingText">
                  {moment(consult?.CompletedDate[0]).format('DD-MM-YYYY')}
                </span>
              </div>
              <div className="w-[30%] flex flex-col items-end">
                <span className="headingText text-coalBlack capitalize line-clamp-1 flex items-center font-bold mr-1">
                  <span>
                    <BsCurrencyRupee />
                  </span>
                  <span>{consult?.AmountPaid}</span>
                </span>
                <div
                  className={`px-3 py-1 mt-1 inline-block baseText rounded-full capitalize user-select-none text-center whitespace-nowrap ${
                    consult?.status == 'pending'
                      ? 'bg-red-200 text-red-500'
                      : 'bg-green-200 text-green-500'
                  }`}
                >
                  {consult.status}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default CreditUsage;
