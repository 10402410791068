import React, { useState } from "react";
import SimpleButton from "../common/buttons/SimpleButton/SimpleButton";
import { EditProfileLoader } from "../common/loader";

function ConfirmPopup({
  message,
  fillBtnTitle,
  unFillBtnTitle,
  popupStatus,
  handelConfirmPopUp,
  onclickFunction,
  forDeleteAccount = false,
}) {
  const [conformPopUp, setConformPopUp] = useState(popupStatus);
  const [loader, setloader] = useState(false);
  //   function handelConfirmPopUp() {
  //     setConformPopUp(!conformPopUp);
  //   }
  const handleClick = async () => {
    setloader(true);
    try {
      const res = await onclickFunction();
      setloader(false);
    } catch (error) {
      console.log(error);
    } finally {
      setloader(false);
    }
  };
  return (
    <div
      className={`fixed top-0 left-0 p-4 w-full h-full bg-black/25  justify-center items-center z-[1000] ${
        conformPopUp ? "flex" : "hidden"
      }`}
    >
      <div className="bg-white px-4 py-8 rounded-3xl shadow-sm space-y-8 w-full max-w-sm md:px-6 md:py-6 md:space-y-10">
        <p className="text-center text-coalBlack baseText">{message}</p>
        <div className="grid grid-cols-2 gap-2">
          {forDeleteAccount ? (
            <SimpleButton
              title={loader ? <EditProfileLoader /> : "Yes"}
              buttonType={"primary"}
              customClass={"rounded-xl"}
              onClickEvent={(e) => {
                e.preventDefault();
                handleClick();
              }}
            />
          ) : (
            <SimpleButton
              title={loader ? <EditProfileLoader /> : "Yes"}
              buttonType={"primary"}
              customClass={"rounded-xl"}
              onClickEvent={handleClick}
            />
          )}

          <SimpleButton
            title={"No"}
            buttonType={"primaryUnfilled"}
            customClass={"rounded-xl"}
            onClickEvent={handelConfirmPopUp}
          />
        </div>
      </div>
    </div>
  );
}

export default ConfirmPopup;
