import React, { useState } from 'react';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import {
  ConsultationCompletedAPI,
  ConsultationbyID,
  ReportNoshowAPI,
} from '../../apis';
import toast from 'react-hot-toast';
import { EditProfileLoader } from '../common/loader';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import ConfirmPopup from '../confirmPopup/ConfirmPopup';
import { FormErrorMessage } from '../errorMessages';

function ConsultationStatusBtn({
  consultationid,
  closeFunction,
  Consultationstatus,
  reload,
  data,
  consultationDetails,
}) {
  console.log('consultationDetails', consultationDetails?.RequestedDate);

  const [loader, setLoader] = useState(false);
  const [confirmReport, setConfirmReport] = useState(false);
  const [reportLoader, setReportLoader] = useState(false);
  const [reportError, setReportError] = useState(false);
  const completeconsultaiton = async () => {
    try {
      setLoader(true);
      const res = await ConsultationCompletedAPI({
        consultationId: consultationDetails?._id,
      });
      if (res.status === 200) {
        toast.success(res.data.message);
        closeFunction();
        reload();
        // location.reload();
      }
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.statusText;
      // toast.error(message, { id: 'error' });
      console.log(error);
    } finally {
      setLoader(false);
    }
  };
  const handelReportNoShow = async () => {
    setReportLoader(true);
    try {
      const res = await ReportNoshowAPI({
        Id: consultationDetails?._id,
      });

      if (res.status === 200) {
        toast.success('Reported successfully');
        closeFunction();
        reload();
        // location.reload();
      }
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.statusText;
      console.log(error);

      // toast.error(message, { id: 'error' });
    } finally {
      setReportLoader(false);
    }
  };
  const handelConfirmReportNoShow = () => {
    const targetDate = new Date(consultationDetails?.RequestedDate);
    const currentDate = new Date();
    const startOfDayCurrent = new Date(currentDate.setHours(0, 0, 0, 0));
    const startOfDayTarget = new Date(targetDate.setHours(0, 0, 0, 0));
    if (startOfDayCurrent > startOfDayTarget) {
      setConfirmReport(!confirmReport);
    } else {
      setReportError(true);
    }
  };

  const message = `Are you sure you want to report Dr.${consultationDetails?.AcceptedDoctor?.DoctorName} as No Show`;
  // console.log(consultationDetails?.status);
  // console.log(consultationDetails?.RequestedDate);

  return (
    <>
      {consultationDetails?.status === 'completed' ? (
        <div className="my-4 inline-flex items-center gap-2 mx-auto py-2 px-4 text-green-600 bg-green-200 min-h-11 rounded-2xl w-full flex items-center justify-center">
          <div>
            <IoMdCheckmarkCircle className="text-2xl" />
          </div>
          <p className=" inline-block  text-center font-medium capitalize">
            consultation completed
          </p>
        </div>
      ) : consultationDetails?.status == 'no-show' ? (
        <div className="my-4 inline-flex items-center gap-2 mx-auto py-2 px-4 text-red-600 bg-red-200 min-h-11 rounded-2xl w-full flex items-center justify-center">
          <div>{/* <IoMdCheckmarkCircle className="text-2xl" /> */}</div>
          <p className=" inline-block  text-center font-medium capitalize">
            Reported No Show
          </p>
        </div>
      ) : (
        <div className="text-base bg-white mt-4 lg:p-4 lg:rounded-3xl">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
            <SimpleButton
              title={loader ? <EditProfileLoader /> : 'Completed'}
              buttonType={'primary'}
              customClass={'rounded-lg'}
              onClickEvent={completeconsultaiton}
            />
            <SimpleButton
              title={'Report No-Show'}
              customClass={'text-red-500 border border-red-500 rounded-lg'}
              onClickEvent={handelConfirmReportNoShow}
              // buttonFunction="withloader"
              // loading={reportLoader}
            />
          </div>
          <div className="mt-2 mx-2 text-xs">
            <span>
              If the hospital doesn't complete the consultation, it will
              automatically be marked as completed after 24 hours.
            </span>
          </div>
          {reportError && (
            <div className="flex lg:justify-center mt-1">
              <FormErrorMessage error={'Requested date has not passed.'} />
            </div>
          )}
        </div>
      )}
      {confirmReport && (
        <ConfirmPopup
          popupStatus={confirmReport}
          confirmtext={reportLoader ? <EditProfileLoader /> : 'Yes'}
          canceltext={'No'}
          message={message}
          onclickFunction={handelReportNoShow}
          handelConfirmPopUp={handelConfirmReportNoShow}
        />
      )}
    </>
  );
}

export default ConsultationStatusBtn;
