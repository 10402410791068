import React from 'react';
import { IoMdCall } from 'react-icons/io';
import { FaSquareCheck } from 'react-icons/fa6';
import { IoVideocam } from 'react-icons/io5';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import ConsultationStatusBtn from './ConsultationStatusBtn';
import qualificationimg from '../../Icons/doccertificate.png';

function AttendingDoctor({
  data,
  dateaccepted,
  consultationid,
  closeFunction,
  Consultationstatus,
  doctorSpecialization,
  reload,
  consultationDetails
}) {
  function getInitials(name) {
    const nameArray = name.split(' ');
    return nameArray
      .map((word) => word[0])
      .join('')
      .toUpperCase();
  }

  function getBackgroundColor(name) {
    // Generate a background color based on the hash of the name
    const hashCode = name
      .split('')
      .reduce((acc, char) => char.charCodeAt(0) + acc, 0);
    const colors = ['#f6ad55', '#f687b3', '#68d391', '#63b3ed', '#a0aec0'];
    return colors[hashCode % colors.length];
  }

  function Avatar({ name }) {
    const initials = getInitials(name);
    const bgColor = getBackgroundColor(name);

    return (
      <div
        className="w-10 h-10 rounded-full flex justify-center items-center"
        style={{ backgroundColor: bgColor }}
      >
        <span className="text-white font-semibold text-xs">{initials}</span>
      </div>
    );
  }
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = {
      weekday: 'short',
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour12: false,
    };
    return date.toLocaleDateString('en-US', options);
  }

  function getDayAlone(dateString) {
    const date = new Date(dateString);
    return date.getDate();
  }
  return (
    <>
      <section className="text-base bg-white mt-4 lg:p-4 lg:rounded-3xl ">
        <div className="AttendingDoctor">
          <p className="text-lg font-medium text-coalBlack xl:text-xl">
            Attending Doctor
          </p>
        </div>
        <div className="consultationType mb-2.5 space-y-2 lg:hidden">
          <div className="bg-lightBlue px-2 py-5 rounded-xl">
            <div className="flex items-center gap-2">
              {
                data?.coverPic ? (
                  <img
                    src={data?.coverPic}
                    className="w-10 h-10 rounded-full bg-gray-200 object-cover"
                  />
                ) : (
                  <div className="min-w-10 h-10 rounded-full bg-gray-200">
                    <Avatar name={data?.DoctorName} />
                  </div>
                )
                // <Avatar name={data?.DoctorName} />
              }
              <p className="text-coalBlack font-medium text-sm max-w-[24ch] truncate text-ellipsis">
                Dr. {data?.DoctorName}
              </p>
            </div>
          </div>
        </div>
        {/* large device  */}
        <div className="grid-cols-2 gap-x-2 gap-y-4 consultationTypeLg hidden lg:grid">
          <div className="bg-white shadow-blue_dropshadow rounded-2xl">
            <p className="font-medium text-base text-[#A3AED0] px-3 py-1">
              Doctor Name
            </p>
            <p className="text-sm font-medium text-coalBlack line-clamp-1 pl-4">
              Dr. {data?.DoctorName}
            </p>
          </div>
          <div className="bg-white shadow-blue_dropshadow rounded-2xl">
            <p className="font-medium text-base text-[#A3AED0] px-3 py-1">
              Qualification
            </p>
            <div className="inline-flex items-center gap-2 px-3 py-1 rounded-lg ">
              <p className="text-sm font-medium text-coalBlack capitalize">
                {doctorSpecialization}
              </p>
            </div>
          </div>
          <div className="bg-white shadow-blue_dropshadow rounded-2xl">
            <p className="font-medium text-base text-[#A3AED0] px-3 py-1">
              Accepted Date
            </p>
            <div className="inline-flex items-center gap-2 px-3 py-1 rounded-lg ">
              <div className="min-w-8 h-8 rounded-md bg-sky-200 inline-flex justify-center items-center">
                <span className="m-auto font-medium text-sky-500">
                  {getDayAlone(dateaccepted)}
                </span>
              </div>
              <p className="text-sm font-medium text-coalBlack max-w-36 truncate whitespace-nowrap text-ellipsis">
                {formatDate(dateaccepted)}
              </p>
            </div>
          </div>
          <div className="bg-white shadow-blue_dropshadow rounded-2xl">
            <p className="font-medium text-base text-[#A3AED0] px-3 py-1">
              Contact Doctor
            </p>
            <div className="inline-flex items-center gap-2 px-3 py-1 rounded-lg ">
              <IoMdCall className="text-accent text-xl" />
              <p className="text-sm font-medium text-coalBlack">
                +91 {data?.reference?.contactNo}
              </p>
            </div>
          </div>
        </div>
        <div className="referenceDoctor  lg:hidden">
          <div className="grid grid-cols-1  gap-3 lg:grid-cols-2">
            <div className="bg-lightBlue px-2 py-5 rounded-xl">
              <div className="flex items-center gap-2">
                <img
                  src={qualificationimg}
                  className="min-w-10 h-10 rounded-full bg-transparent"
                />
                <p className="text-coalBlack font-medium text-sm max-w-[24ch] truncate text-ellipsis">
                  {doctorSpecialization}
                </p>
              </div>
            </div>
            <div className="bg-lightBlue px-2 py-5 rounded-xl">
              <div className="inline-flex items-center gap-2 rounded-lg ">
                <div className="min-w-10 h-10 rounded-md bg-sky-200 inline-flex justify-center items-center">
                  <span className="m-auto font-medium text-sky-500">
                    {getDayAlone(dateaccepted)}
                  </span>
                </div>
                <p className="text-sm font-medium text-coalBlack max-w-36 truncate whitespace-nowrap text-ellipsis">
                  {formatDate(dateaccepted)}
                </p>
              </div>
            </div>
            <div className="bg-lightBlue px-2 py-5 rounded-xl">
              <div className="flex items-center gap-2">
                <IoMdCall className="text-accent text-2xl min-w-10 w-10" />
                <div>
                  <p className="text-coalBlack font-medium text-sm">
                    +91 {data?.reference?.contactNo}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="lg:block hidden">
        <ConsultationStatusBtn
          consultationid={consultationid}
          closeFunction={closeFunction}
          Consultationstatus={Consultationstatus}
          reload={reload}
          data={data}
          consultationDetails={consultationDetails}
        />
      </div>
    </>
  );
}

export default AttendingDoctor;
