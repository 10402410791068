import React, { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import NotFound from '../Pages/404';
import Otp from '../Pages/Account/Otp';
import PrivateRoute from './privateRoute';
import PublicRoute from './PublicRoute';
import GoogleSignIn from '../Pages/Account/googleSignIn';
import { getUserApi } from '../apis/index.js';
import Registration from '../Pages/RegistrationPage/Registration.js';
import DashboardOdds from '../Pages/DashboardOdds/Index.js';
import NotificationOdds from '../Pages/NotificationOdds/NotificationOdds.js';
import Index from '../Pages/Consultation/Index.js';
import ProfileLayout from '../Pages/ProfileLayout/ProfileLayout.js';
import PersonalDetails from '../Pages/ProfileLayout/PersonalDetails.js';
import AppointmentHistory from '../Pages/ProfileLayout/AppoinmentHistory.js';
import AboutUs from '../Pages/ProfileLayout/AboutUs.js';
import DeleteAccount from '../Pages/ProfileLayout/DeleteAccount.js';
import Logout from '../Pages/ProfileLayout/Logout.js';
import ChangePassword from '../Pages/ProfileLayout/ChangePassword.js';
import WalletIndex from '../Pages/Wallet/WalletIndex.js';
import HelpCenter from '../Pages/ProfileLayout/Helpcenter.js';
import PrivacyPolicy from '../Pages/ProfileLayout/PrivacyPolicy.js';
import { logoutOnJwtExpire } from '../utils/helperFunctions.js';

export default function MainRouter() {
  const [loggedInUser, setLoggedInUser] = useState();
 const navigate = useNavigate();
  const getLoggedInUser = async () => {
    try {
      const res = await getUserApi();
      if (res.status === 200) {
        setLoggedInUser(res.data.Profile);
      }
    } catch (error) {
      console.log("MainRouter",error);
      console.log(error?.response)
           if (error?.response?.status === 401) {
                   logoutOnJwtExpire();
                   navigate('/sign-in');
                 }
    }
  };
  useEffect(() => {
    getLoggedInUser();
  }, []);

  return (
    <>
      <Routes>
        <Route />
        <Route
          path="/sign-up"
          element={
            <PublicRoute>
              <GoogleSignIn
                formType={'SIGNUP'}
                formGreetings={'Join Hospital Portal !'}
                formSubGreetings={'Enter your email and password to sign up'}
              />
            </PublicRoute>
          }
        />
        <Route
          path="/sign-in"
          element={
            <PublicRoute>
              <GoogleSignIn
                formGreetings={'Welcome Back !'}
                formSubGreetings={'Enter your email and password to sign in'}
              />
            </PublicRoute>
          }
        />
        <Route
          path="/otp"
          element={
            <PublicRoute>
              <Otp />
            </PublicRoute>
          }
        />
        <Route
          path="Registration"
          element={
            <PublicRoute>
              <Registration />
            </PublicRoute>
          }
        />

        <Route
          path="/"
          element={
            <PrivateRoute>
              <DashboardOdds currentUser={loggedInUser} />
            </PrivateRoute>
          }
        />
      
        <Route
          path="notification"
          element={
            <PrivateRoute>
              <NotificationOdds />
            </PrivateRoute>
          }
        />
        <Route
          path="consultation"
          element={
            <PrivateRoute>
              <Index />
            </PrivateRoute>
          }
        />
        <Route
          path="/payments"
          element={
            <PrivateRoute>
              <WalletIndex />
            </PrivateRoute>
          }
        />

        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <ProfileLayout />
            </PrivateRoute>
          }
        >
          <Route
            path="/profile/appointment"
            element={
              <PrivateRoute>
                <AppointmentHistory />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile/details"
            element={
              <PrivateRoute>
                <PersonalDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile/help"
            element={
              <PrivateRoute>
                <HelpCenter />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile/changepassword"
            element={
              <PrivateRoute>
                <ChangePassword/>
              </PrivateRoute>
            }
          />
           <Route
            path="/profile/privacy"
            element={
              <PrivateRoute>
                <PrivacyPolicy />
              </PrivateRoute>
            }
          />
         <Route
            path="/profile/aboutus"
            element={
              <PrivateRoute>
                <AboutUs />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile/deleteaccount"
            element={
              <PrivateRoute>
                <DeleteAccount />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile/logout"
            element={
              <PrivateRoute>
                <Logout />
              </PrivateRoute>
            }
          />
          
        </Route>
        <Route
          path="*"
          element={
            <PrivateRoute>
              <NotFound />
            </PrivateRoute>
          }
        />
      </Routes>
      <Toaster
        position="top-center"
        reverseOrder={false}
        containerStyle={{ zIndex: '99999' }}
      />
    </>
  );
}


