import { Form, useFormik } from 'formik';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { editUserProfile } from '../../components/Validation';
import { EditProfileLoader } from '../../components/common/loader';
import { InputErrorMessage } from '../../components/errorMessages';
import { FaPencil } from 'react-icons/fa6';
import SimpleButton from '../../components/common/buttons/SimpleButton/SimpleButton';
import { CgCalendarDates } from 'react-icons/cg';
import toast from 'react-hot-toast';
import { MdPhotoCamera } from 'react-icons/md';
import ProfileTopNavigation from './ProfileTopNavigation';
import { UpdateUserDetails, fileUploadApi, getUserApi } from '../../apis';
import { AppContext } from '../../appContext';
import { useDropzone } from 'react-dropzone';

function PersonalDetails() {
  const [profileData, setprofileData] = useState('');
  const { fetchUserProfile } = useContext(AppContext);
  const fetchData = async () => {
    try {
      const res = await getUserApi();
      if (res.status === 200) {
        setprofileData(res?.data?.Profile);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  console.log('profileData', profileData);
  const [editloader, seteditloader] = useState(false);
  const formik = useFormik({
    initialValues: {
      HospitalName: profileData?.hospitalProfile?.hospitalName || '',
      HospitalAddress: profileData?.hospitalProfile?.address || '',
      userEmail: profileData?.email || '',
      contactNumber: profileData?.hospitalProfile?.refPhoneNo || '',
      profile: profileData?.hospitalProfile?.profilePic || '',
      latitude: profileData?.hospitalProfile?.lat || '',
      longitude: profileData?.hospitalProfile?.long || '',
    },
    enableReinitialize: true,
    validationSchema: editUserProfile,
    onSubmit: async (values) => {
      console.log(values);
      seteditloader(true);
      const {
        HospitalName,
        HospitalAddress,
        userEmail,
        contactNumber,
        profile,
        latitude,
        longitude,
      } = values;
      
      const payloadFormate = {
        hospitalName: HospitalName,
        address: HospitalAddress,
        profileId: profileData.hospitalProfile?._id,
        email: userEmail,
        refPhoneNo: contactNumber,
        profilePic: profile,
        lat: latitude,
        long: longitude,
      };
      try {
        const res = await UpdateUserDetails(payloadFormate);
        if (res.status === 200) {
          fetchUserProfile();
          toast.success('Profile updated successfully');
        }
      } catch (error) {
        console.log(error.response.data.error);
        const message = error.response.data.error || error.response.statusText;
        toast.error(message, { id: 'error' });
      } finally {
        seteditloader(false);
      }
    },
  });
  const { userEmail, contactNumber } = formik.values;
  const handleChange = (e) => {
    e.preventDefault();
    formik.handleChange(e);
  };
  const onDropProfile = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      await profilefileUpload(file);
    }
  }, []);
  const profileonUploadProgress = (progressEvent, file) => {
    console.log(progressEvent);
  };
  const [isprofile, setisprofile] = useState(false);
  const profilefileUpload = async (file) => {
    try {
      setisprofile(true);
      const formData = new FormData();
      formData.append('file', file);
      // Make the file upload request
      const uploadResponse = await fileUploadApi(formData, (progressEvent) =>
        profileonUploadProgress(progressEvent, file)
      );

      if (uploadResponse.status === 200 || uploadResponse.status === 201) {
        // Here we update only the profile field
        setisprofile(false);
        formik.setFieldValue('profile', `${uploadResponse.data.filePath}`);
      } else {
        setisprofile(false);
        toast.error('image upload failed', { is: 'err' });
      }
    } catch (error) {
      setisprofile(false);
      console.error('An error occurred:', error);
      toast.error('image upload failed', { is: 'err' });
    } finally {
      setisprofile(false);
    }
  };

  const {
    getRootProps: getRootPropsProfile,
    getInputProps: getInputPropsProfile,
    isDragActive: isDragActiveProfile,
  } = useDropzone({
    onDrop: onDropProfile,
  });

  return (
    <div className="text-coalBlack">
      <ProfileTopNavigation pageTitle={'Personal Details'} />
      <section className="my-7 max-w-md me-auto px-4">
        <div className="profileImage">
          <div
            className="size-16 rounded-full ms-10 relative flex items-center justify-center"
            {...getRootPropsProfile()}
          >
            {isprofile && <EditProfileLoader />}
            {formik.values.profile ? (
              <img
                src={`${process.env.REACT_APP_API_URL}/${formik.values.profile}`}
                alt="Profile"
                className="w-full h-full object-cover rounded-full"
                style={{ display: isprofile ? 'none' : 'block' }}
              />
            ) : (
              <div className="w-full h-full bg-gray-200 rounded-full flex items-center justify-center">
                <MdPhotoCamera className="text-white text-base" />
              </div>
            )}
            <div className="bg-black/25 p-1.5 rounded-full absolute bottom-0 -right-2">
              <MdPhotoCamera className="text-white text-base" />
            </div>
          </div>
          <input {...getInputPropsProfile()} />
          <p className="my-1 py-1 smallText  text-navLink">
            {formik.values.profile ? 'Edit' : 'Upload'} your profile image
          </p>
        </div>

        <form className="mt-11 space-y-4">
          <h2 className="subHeadingText">Hospital information</h2>
          <div className="HospitalName">
            <p className="baseText text-coalBlack mb-2 capitalize">
              Hospital Name
            </p>
            <div className="relative">
              <input
                type="text"
                name="HospitalName"
                className={
                  Boolean(formik.touched.HospitalName) &&
                  Boolean(formik.errors.HospitalName)
                    ? 'pe-9 form-control line-clamp-1 border-danger transparent-input-border'
                    : 'pe-9 form-control line-clamp-1 transparent-input-border'
                }
                id="HospitalName"
                placeholder="Enter hospital name :"
                value={formik.values.HospitalName}
                title={formik.values.HospitalName}
                onChange={handleChange}
                maxLength={30}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-z. ]/g, ""); // Remove invalid characters
                }}
              />
              <FaPencil className="absolute top-1/2 -translate-y-1/2 right-[14px] bg-white text-gray-400" />
            </div>
            <InputErrorMessage
              error={formik.touched.HospitalName && formik.errors.HospitalName}
            />
          </div>
          <div className="HospitalAddress">
            <p className="baseText text-coalBlack mb-2 capitalize">
              Hospital address
            </p>
            <div className="relative">
              <input
                type="text"
                name="HospitalAddress"
                className={
                  Boolean(formik.touched.HospitalAddress) &&
                  Boolean(formik.errors.HospitalAddress)
                    ? 'pe-9 form-control line-clamp-1 border-danger transparent-input-border'
                    : 'pe-9 form-control line-clamp-1 transparent-input-border'
                }
                id="HospitalAddress"
                placeholder="Enter hospital address :"
                value={formik.values.HospitalAddress}
                title={formik.values.HospitalAddress}
                onChange={handleChange}
              />
              <FaPencil className="absolute top-1/2 -translate-y-1/2 right-[14px] bg-white text-gray-400" />
            </div>
            <InputErrorMessage
              error={
                formik.touched.HospitalAddress && formik.errors.HospitalAddress
              }
            />
          </div>
          <h2 className="subHeadingText">Personal information</h2>
          <div className="userEmail">
            <p className="baseText text-coalBlack mb-2 capitalize">
              Representative Email
            </p>
            <div className="relative">
              <input
                type="text"
                name="userEmail"
                className={
                  Boolean(formik.touched.userEmail) &&
                  Boolean(formik.errors.userEmail)
                    ? 'pe-9 form-control line-clamp-1 border-danger transparent-input-border'
                    : 'pe-9 form-control line-clamp-1 transparent-input-border'
                }
                id="userEmail"
                placeholder="Enter name :"
                value={formik.values.userEmail}
                title={formik.values.userEmail}
                onChange={handleChange}
              />
              <FaPencil className="absolute top-1/2 -translate-y-1/2 right-[14px] bg-white text-gray-400" />
            </div>
            <InputErrorMessage
              error={formik.touched.userEmail && formik.errors.userEmail}
            />
          </div>
          <div className="contactNumber">
            <p className="baseText text-coalBlack mb-2 capitalize">
              Representative Contact Number
            </p>
            <div className="relative">
              <input
                type="tel"
                name="contactNumber"
                className={
                  Boolean(formik.touched.contactNumber) &&
                  Boolean(formik.errors.contactNumber)
                    ? 'pe-9 form-control border-danger transparent-input-border'
                    : 'pe-9 form-control transparent-input-border'
                }
                id="contactNumber"
                placeholder="Enter mobile no :"
                value={formik.values.contactNumber}
                onChange={handleChange}
                maxLength={10} // Ensures max 10 characters
                pattern="\d{10}" // Ensures exactly 10 digits in a form submission
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/\D/g, '').slice(0, 10); // Allows only digits
                }}
              />
              <FaPencil className="absolute top-1/2 -translate-y-1/2 right-4 text-gray-400" />
            </div>
            <InputErrorMessage
              error={
                formik.touched.contactNumber && formik.errors.contactNumber
              }
            />
          </div>
          <div className="text-sm text-gray-300">
            Note:
            <p className="hidden lg:block">
              Right-click the desired location and choose "What's here?" to see
              the coordinates.
            </p>
            <p className="block lg:hidden">
              Tap and hold on the desired location, then check the coordinates
              displayed at the bottom of the screen.
            </p>
          </div>

          <div className="latitude">
            <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
              Latitude
            </p>
            <div className="relative">
              <div className="relative">
                <input
                  type="tel"
                  name="latitude"
                  className={
                    Boolean(formik.touched.latitude) &&
                    Boolean(formik.errors.latitude)
                      ? 'pe-9 form-control border-danger transparent-input-border'
                      : 'pe-9 form-control transparent-input-border'
                  }
                  id="latitude"
                  placeholder="30.123456"
                  value={formik.values.latitude}
                  onChange={handleChange}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9.]/g, ''); // Allows only numbers and dot
                  }}
                  
                />
                <FaPencil className="absolute top-1/2 -translate-y-1/2 right-4 text-gray-400" />
              </div>
              <InputErrorMessage
                error={formik.touched.latitude && formik.errors.latitude}
              />
            </div>
          </div>
          <div className="longitude">
            <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
              Longitude
            </p>
            <div className="relative">
              <div className="relative">
                <input
                  type="tel"
                  name="longitude"
                  className={
                    Boolean(formik.touched.longitude) &&
                    Boolean(formik.errors.longitude)
                      ? 'pe-9 form-control border-danger transparent-input-border'
                      : 'pe-9 form-control transparent-input-border'
                  }
                  id="longitude"
                  placeholder="40.123456"
                  value={formik.values.longitude}
                  onChange={handleChange}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9.]/g, ''); // Allows only numbers and dot
                  }}
                  
                />
                <FaPencil className="absolute top-1/2 -translate-y-1/2 right-4 text-gray-400" />
              </div>
              <InputErrorMessage
                error={formik.touched.longitude && formik.errors.longitude}
              />
            </div>
          </div>

          <SimpleButton
            customClass={'bg-accent text-white rounded-lg w-full'}
            buttonType={'primary'}
            title={editloader ? <EditProfileLoader /> : 'Update'}
            onClickEvent={formik.handleSubmit}
          />
        </form>
      </section>
    </div>
  );
}

export default PersonalDetails;
