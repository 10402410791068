import React, { useEffect, useState } from 'react';
import { FaLocationDot } from 'react-icons/fa6';
import { IoMdCall } from 'react-icons/io';
import { FaSquareCheck } from 'react-icons/fa6';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import { FiChevronLeft } from 'react-icons/fi';
import { IoVideocam } from 'react-icons/io5';
import { BiSolidReport } from 'react-icons/bi';
import { FaFilePrescription } from 'react-icons/fa';
import { GrNotes } from 'react-icons/gr';
import { RiChat1Line } from 'react-icons/ri';
import MedicalRecord from '../MedicalRecord/MedicalRecord';
import AttendingDoctor from './AttendingDoctor';
import { Person } from 'react-bootstrap-icons';
import { PiStethoscopeFill } from 'react-icons/pi';
import { PiGenderMaleBold } from 'react-icons/pi';
import { PiGenderFemaleBold } from 'react-icons/pi';
import ConsultationStatusBtn from './ConsultationStatusBtn';
import { ConsultationbyID } from '../../apis';
import toast from 'react-hot-toast';
import { CgCalendarDates } from 'react-icons/cg';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import HospitalChat from '../oddsChat/HospitalChat';
import { BsFillChatLeftDotsFill } from 'react-icons/bs';
import { formatDate } from '../Helperfunction/timeconverter';
import { FiInfo } from 'react-icons/fi';
import Avatar from '../Avatar/Avatar'
function ConsultationViewRequest({
  closeFunction,
  currentdata,
  profileData,
  reload,
}) {
  console.log(currentdata);
  console.log(profileData);
  const [medicalRecordScreen, setMedicalRecordscreen] = useState(false);
  const [chatBlock, setChatBlock] = useState(false);
  const handelMedicalRecordScreen = () => {
    setMedicalRecordscreen(!medicalRecordScreen);
  };
  function gettypeText() {
    const text = {
      inperson: 'In-Person ',
      Virtual: 'Virtual ',
    };
    return text[data?.consType];
  }
  function getTypeIcon(text, type) {
    const icons = {
      inperson: (
        <div
          className={`${
            type === 'lg' ? 'min-w-10 h-10' : ' min-w-8 h-8'
          } rounded-lg bg-blue-100 flex justify-center items-center`}
        >
          <Person
            className={`${
              type === 'lg' ? 'text-2xl' : 'text-lg'
            } text-blue-700`}
          />
        </div>
      ),
      Virtual: (
        <div
          className={`${
            type === 'lg' ? 'min-w-10 h-10' : ' min-w-8 h-8'
          } rounded-lg bg-purple-100 flex justify-center items-center`}
        >
          <IoVideocam
            className={`${
              type === 'lg' ? 'text-2xl' : 'text-lg'
            } text-purple-700`}
          />
        </div>
      ),
      // Add more cases if needed
    };
    return icons[text] || null; // Return null if type doesn't match any case
  }
  const [data, setdata] = useState(null);
  console.log(currentdata);
  const getconsultaitonbyid = async () => {
    try {
      const res = await ConsultationbyID(currentdata);
      if (res.status === 200) {
        console.log(res.data.message);
        console.log(res.data);
        setdata(res.data?.consultationdata);
      }
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.statusText;
      toast.error(message, { id: 'error' });
    } finally {
    }
  };

  useEffect(() => {
    getconsultaitonbyid();
  }, []);
  const handelChatBlock = () => {
    setChatBlock(!chatBlock);
  };
  console.log(data);
  console.log(data?.hospitalProfilePhoto);
  return (
    <>
      <section className="bg-black/25 fixed top-0 left-0 w-full h-screen z-50 flex justify-center items-center  lg:z-[10000]">
        <div
          className="absolute top-0 left-0 w-full h-full bg-inherit"
          onClick={closeFunction}
        ></div>
        <div className="bg-bodybackground relative z-20 m-auto overflow-y-auto overscroll-contain scrollbar w-full max-h-screen lg:max-w-4xl xl:max-w-5xl lg:py-4 lg:px-8 lg:max-h-[96vh]  lg:rounded-3xl ">
          {/* LG Navigation  */}
          <div className="Navigation justify-between items-center hidden py-4 lg:flex ">
            <p className="text-coalBlack text-lg font-medium capitalize">
              {/* Consultation View Specalaization */}
              {data?.Specalaization?.name}
            </p>
            <div className="hidden lg:block mr-auto ps-2">
              {data?.method === 'private' &&
                data?.selecteddoctor.length == 0 && (
                <div className="flex  gap-x-1">
                  <FiInfo className="text-red-500 text-xl" />
                  <p className="baseText leading-3 font-normal text-red-500 ">
                      Action required: The doctor declined the consultation.{' '}
                  </p>
                </div>
              )}
            </div>
            <p
              className="text-gray-500 text-sm font-medium min-w-10 p-2 cursor-pointer"
              onClick={closeFunction}
            >
              Close
            </p>
          </div>
          <main className="bg-white p-4 pt-0 pb-44  space-y-3.5  lg:rounded-2xl lg:p-0 lg:bg-inherit">
            {/* SM Navigation  */}
            <div className=" bg-white flex items-center gap-1 sticky top-0 py-4 z-30 lg:hidden">
              <FiChevronLeft
                className="text-2xl text-coalBlack w-9 h-9 p-1 rounded-full active:bg-sky-50"
                onClick={closeFunction}
              />
              <p className="headingText text-coalBlack capitalize">
                {data?.Specalaization?.name}
              </p>
              <p
                className={`baseText !font-medium px-4 py-1.5 rounded-full ${
                  data?.priority === 'urgent' ? 'bg-[#FF4539]' : 'bg-[#7be7b2]'
                } text-white ms-auto`}
              >
                {data?.priority === 'urgent' ? 'Urgent' : 'Routine'}
              </p>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3  lg:gap-6 place-content-stretch">
              <section className="grid grid-cols-1 place-content-start">
                {/* SM profile viewer  start*/}
                <div className="lg:hidden">
                  {data?.method === 'private' &&
                    data?.selecteddoctor.length == 0 && (
                    <div className="flex  gap-x-1">
                      <FiInfo className="text-red-500 text-xl" />
                      <p className="baseText leading-3 font-normal text-red-500 mb-2.5">
                          Action required: The doctor declined the consultation.{' '}
                      </p>
                    </div>
                  )}
                </div>
                <div className="flex gap-2 lg:hidden">
                  {data?.hospitalId?.profilePic ? (
                    <img
                      className="min-w-32 h-32 rounded-xl bg-gray-200 object-cover aspect-square"
                      src={data?.hospitalId?.profilePic}
                    />
                  ) : (
                    // <div
                    //   className="min-w-32 h-32 rounded-xl bg-gray-200 animate-pulse  "
                    //   role="status"
                    // ></div>
                    <div className="size-32 basis-32 rounded-xl bg-gray-200 flex justify-center items-center">
                      <p className="subHeadingText capitalize">
                        {data?.hospitalId?.hospitalName}
                      </p>
                    </div>
                  )}

                  <div className="space-y-3">
                    <p className="text-accent headingText capitalize">
                      {data?.hospitalId?.hospitalName}
                    </p>
                    <div className="flex items-center gap-4">
                      {data?.Consultationstatus === 'accepted' &&
                        data?.status != 'completed' && (
                        <div
                          className="flex justify-center items-center bg-lightBlue rounded-full cursor-pointer"
                          onClick={handelChatBlock}
                        >
                          <BsFillChatLeftDotsFill className="text-xl size-10 text-accent p-2" />
                        </div>
                      )}
                      {/* <div className="flex justify-center items-center bg-lightBlue rounded-full cursor-pointer">
                      <IoMdCall className="text-xl min-w-9 h-9 text-accent p-2" />
                    </div>
                    <div className="flex justify-center items-center bg-lightBlue rounded-full cursor-pointer">
                      <IoVideocam className="text-xl min-w-9 h-9 text-accent p-2" />
                    </div> */}
                    </div>
                  </div>
                </div>
                <div className="text-sm font-normal text-coalBlack bg-lightBlue p-3 rounded-lg space-y-3 lg:hidden my-2">
                  <div className="flex items-start gap-2">
                    <FaLocationDot className="text-xl text-accent min-w-4" />
                    <p className="baseText text-navLink">
                      {data?.hospitalAddress}
                    </p>
                  </div>
                  <div className="flex items-start gap-2">
                    <IoMdCall className="text-xl text-accent min-w-4" />
                    <p className="baseText text-navLink">
                      {profileData?.hospitalProfile?.refCountryCode}
                      {data?.hospitalContactNumber}
                    </p>
                  </div>
                </div>
                {/* SM profile viewer  end*/}
                {/* LG profile viewer  */}
                <div className="profileForLargeDevice rounded-3xl bg-white p-4 space-y-3 mb-4 hidden lg:block">
                  <div>
                    {data?.hospitalId?.profilePic ? (
                      <img
                        className="h-36 rounded-2xl bg-white w-[100%] object-cover aspect-square"
                        src={data?.hospitalId?.profilePic}
                      />
                    ) : (
                      <div
                        className="bg-gray-100 flex justify-center items-center h-36 rounded-2xl "
                        role="status"
                      >
                        <p className="subHeadingText text-center capitalize py-6">
                          {' '}
                          {data?.hospitalId?.hospitalName}
                        </p>
                      </div>
                    )}
                    {data?.hospitalId?.profilePic ? (
                      <img
                        className="w-14 h-14 bg-white rounded-full relative top-[-30px] left-1/2 -translate-x-1/2 border border-white object-cover"
                        src={data?.hospitalId?.profilePic}
                      />
                    ) : (
                      <div
                        role="status"
                        className="bg-gray-50 dark:bg-gray-700 w-14 h-14  rounded-full relative top-[-30px] left-1/2 -translate-x-1/2 border border-white"
                      >
                        <Avatar name={data?.hospitalId?.hospitalName || ''} size="full"/>
                      </div>
                    )}
                  </div>
                  <div className="px-6 space-y-3">
                    <p className="text-coalBlack text-center font-medium text-xl capitalize">
                      {data?.hospitalId?.hospitalName}
                    </p>
                    <div className="flex items-start gap-2">
                      <FaLocationDot className="text-xl text-accent min-w-4" />
                      <p className="text-base font-medium text-gray-400">
                        {data?.hospitalAddress}
                      </p>
                    </div>
                    <div className="flex items-start gap-2">
                      <IoMdCall className="text-xl text-accent min-w-4" />
                      <p className="text-base font-medium text-gray-400">
                        {profileData?.hospitalProfile?.refCountryCode}
                        {data?.hospitalContactNumber}
                      </p>
                    </div>
                  </div>
                </div>
                <section className="text-base space-y-6 bg-white lg:p-4 lg:rounded-3xl mt-auto">
                  <div className="space-y-2 requestDescription">
                    <p className="subHeadingText text-coalBlack mt-2 lg:mt-0">
                      Request Description
                    </p>
                    <p className="baseText text-navLink">
                      {data?.RequestDescription}
                    </p>
                  </div>
                  <div className="consultationType space-y-2 lg:hidden">
                    <div className="flex items-center gap-1">
                      <p className="font-medium text-coalBlack">
                        Consultation Type
                      </p>
                      <div className="bg-accent w-4 h-4 flex justify-center items-center rounded-full text-white text-[8px]">
                        i
                      </div>
                    </div>
                    <div className="inline-flex items-center gap-3 px-3 py-2 rounded-lg bg-lightBlue">
                      {getTypeIcon(data?.consType, 'lg')}
                      <p className="text-sm font-medium text-coalBlack">
                        {gettypeText()}
                      </p>
                    </div>
                    <div className="consultationType space-y-2 lg:hidden">
                      <div className="flex items-center gap-1">
                        <p className="baseText text-coalBlack">
                          Requested Date
                        </p>
                      </div>
                      <div className="inline-flex items-center gap-3 px-3  pe-10 py-2 rounded-lg bg-lightBlue">
                        <div className="min-w-10 h-10 rounded-lg bg-green-100 flex justify-center items-center">
                          <CgCalendarDates className="text-accent text-xl" />
                        </div>
                        <p className="text-sm font-medium text-coalBlack capitalize">
                          {formatDate(data?.RequestedDate)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="grid-cols-2 gap-x-2 gap-y-4 consultationTypeLg hidden lg:grid">
                    <div className="bg-white shadow-blue_dropshadow rounded-2xl">
                      <p className="font-medium text-base text-[#A3AED0] px-3 py-1">
                        Consultation Type
                      </p>
                      <div className="inline-flex items-center gap-2 px-3 py-1 rounded-lg ">
                        {getTypeIcon(data?.consType, 'sm')}

                        <p className="text-sm font-medium text-coalBlack">
                          {gettypeText()}
                        </p>
                      </div>
                    </div>
                    <div className="bg-white shadow-blue_dropshadow rounded-2xl">
                      <p className="font-medium text-base text-[#A3AED0] px-3 py-1">
                        Request Priority
                      </p>
                      <div className="inline-flex items-center gap-2 px-3 py-1 rounded-lg ">
                        <div className="min-w-4 h-8 rounded-lg  flex justify-center items-center">
                          <FaSquareCheck
                            className={`text-lg ${
                              data?.priority === 'urgent'
                                ? 'text-[#FF637B]'
                                : 'text-[#7be7b2]'
                            } `}
                          />
                        </div>
                        <p className="text-sm font-medium text-coalBlack">
                          {data?.priority === 'urgent' ? 'Urgent' : 'Routine'}
                        </p>
                      </div>
                    </div>
                    <div className="bg-white shadow-blue_dropshadow rounded-2xl">
                      <p className="font-medium text-base text-[#A3AED0] px-3 py-1">
                        Requested Date
                      </p>
                      <div className="inline-flex items-center gap-2 px-3 py-1 rounded-lg ">
                        <CgCalendarDates className="text-accent text-xl" />
                        <p className="text-sm font-medium text-coalBlack">
                          {formatDate(data?.RequestedDate)}
                        </p>
                      </div>
                    </div>
                    <div className="bg-white shadow-blue_dropshadow rounded-2xl">
                      <p className="font-medium text-base text-[#A3AED0] px-3 py-1">
                        Reference Doctor
                      </p>
                      <div className="inline-flex items-center gap-2 px-3 py-1 rounded-lg ">
                        {/* <div className="min-w-8 h-8 rounded-full bg-gray-200"></div> */}
                        <div className="w-6 h-6 bg-sky-100 rounded-full flex justify-center items-center p-1">
                          <PiStethoscopeFill className="text-lg text-accent" />
                        </div>
                        <p className="text-sm font-medium text-coalBlack max-w-36 truncate whitespace-nowrap text-ellipsis">
                          Dr. {data?.doctorDetails?.doctorname}
                        </p>
                      </div>
                    </div>
                    <div className="bg-white shadow-blue_dropshadow rounded-2xl">
                      <p className="font-medium text-base text-[#A3AED0] px-3 py-1">
                        Contact Doctor
                      </p>
                      <div className="inline-flex items-center gap-2 px-3 py-1 rounded-lg ">
                        <IoMdCall className="text-accent text-xl" />
                        <p className="text-sm font-medium text-coalBlack">
                          {data?.doctorDetails?.phone}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="referenceDoctor space-y-2 lg:hidden">
                    <p className="text-lg font-medium text-coalBlack xl:text-xl">
                      Reference Doctor
                    </p>
                    <div className="grid grid-cols-1  gap-2 md:grid-cols-2">
                      <div className="bg-lightBlue px-2 py-5 rounded-xl">
                        <div className="flex items-start gap-2">
                          <div className="w-6 h-6 bg-sky-100 rounded-full flex justify-center items-center p-1">
                            <PiStethoscopeFill className="text-lg text-accent" />
                          </div>
                          <div>
                            <p className="text-coalBlack font-medium text-sm max-w-[24ch] truncate text-ellipsis">
                              Dr. {data?.doctorDetails?.doctorname}
                            </p>
                            <p className="text-coalBlack font-light text-sm max-w-[24ch] truncate text-ellipsis">
                              M.B.B.S general
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="bg-lightBlue px-2 py-5 rounded-xl">
                        <div className="flex items-center gap-2">
                          <IoMdCall className="text-accent text-2xl min-w-10 w-10" />
                          <div>
                            <a
                              className="text-coalBlack font-medium text-sm"
                              href={`tel:${data?.doctorDetails?.phone}`}
                            >
                              Request A call with <br /> Doctor
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                {(data?.Consultationstatus === 'accepted' ||
                  data?.status === 'completed') && (
                  <AttendingDoctor
                    reload={reload}
                    data={data?.AcceptedDoctor}
                    dateaccepted={data?.acceptedDate}
                    consultationid={data?._id}
                    closeFunction={closeFunction}
                    Consultationstatus={data?.status}
                    doctorSpecialization={data?.Specalaization?.name}
                    consultationDetails={data}
                  />
                )}
                {data?.status === 'no-show' ? (
                  <div className="lg:block hidden mt-4">
                    <p className="p-3.5 rounded-2xl  text-center font-medium capitalize bg-red-200 text-red-600">
                      {' '}
                      Reported No Show
                    </p>
                  </div>
                ) : null}
              </section>
              <section className="space-y-6 pb-4 bg-white lg:rounded-3xl lg:p-4">
                <div className="PatientDetail space-y-2">
                  <div className="bg-lightBlue p-3 rounded-xl">
                    <div className="inline-flex gap-1 items-center">
                      <div className="size-9  inline-flex justify-center  items-center">
                        {data?.patientGender == 'male' ? (
                          <PiGenderMaleBold className="text-lg text-blue-400 " />
                        ) : (
                          <PiGenderFemaleBold className="text-lg text-blue-400 " />
                        )}
                      </div>
                      <p className="font-medium text-sm">
                        <span className="capitalize me-1">
                          {data?.patientGender},{' '}
                        </span>
                        <span>{data?.patientAge} years</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="MedicalDetail space-y-2 hidden">
                  <p className="text-lg font-medium text-coalBlack xl:text-xl">
                    Medical Detail
                  </p>
                  <div className="flex flex-wrap gap-3 items-center">
                    {data?.medicalTag?.map((tag, index) => (
                      <div
                        key={index}
                        className="px-4 py-2 rounded-full text-coalBlack bg-gray-100 capitalize"
                      >
                        {tag}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="DoctorsDescription space-y-2 hidden">
                  <p className="text-lg font-medium text-coalBlack xl:text-xl">
                    Doctors Description
                  </p>
                  <p className="text-base font-normal text-gray-500">
                    {data?.doctorNotes}
                  </p>
                </div>
                <div className="MedicalRecords space-y-2">
                  <div className="flex items-center">
                    <p className="text-lg font-medium text-coalBlack xl:text-xl">
                      Medical Records
                    </p>
                    <p
                      className="text-sm text-gray-400 font-normal ms-auto cursor-pointer"
                      onClick={handelMedicalRecordScreen}
                    >
                      View All
                    </p>
                  </div>
                  <div className="flex items-center gap-6 px-2 py-3 rounded-lg bg-lightBlue">
                    <div className="inline-flex flex-col gap-1 justify-center items-center">
                      <BiSolidReport className="text-2xl text-accent" />
                      <p className="text-sm font-normal text-accent  capitalize">
                        Report
                      </p>
                    </div>
                    <div className="inline-flex flex-col gap-1 justify-center items-center">
                      <FaFilePrescription className="text-2xl text-accent" />
                      <p className="text-sm font-normal text-accent  capitalize">
                        Prescription
                      </p>
                    </div>
                    <div className="inline-flex flex-col gap-1 justify-center items-center">
                      <GrNotes className="text-2xl text-accent" />
                      <p className="text-sm font-normal text-accent  capitalize">
                        Notes
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:hidden block">
                  {data?.Consultationstatus == 'accepted' ||
                  data?.Consultationstatus == 'completed' ? (
                      <ConsultationStatusBtn
                        consultationid={data?._id}
                        closeFunction={closeFunction}
                        Consultationstatus={data?.status}
                        reload={reload}
                        data={data?.AcceptedDoctor}
                        consultationDetails={data}
                      />
                    ) : data?.Consultationstatus == 'no-show' ? (
                      <ConsultationStatusBtn
                        consultationid={data?._id}
                        closeFunction={closeFunction}
                        Consultationstatus={data?.status}
                        reload={reload}
                        data={data?.AcceptedDoctor}
                        consultationDetails={data}
                      />
                    ) : null}
                </div>
                {data?.Consultationstatus === 'accepted' &&
                  data?.status != 'completed' && (
                  <div className="CommunicationChannels space-y-2 hidden lg:block">
                    <p className="text-lg font-medium text-coalBlack xl:text-xl">
                        Communication Channels
                    </p>
                    <div className="flex items-center gap-6">
                      <div
                        className="flex justify-center items-center bg-accent rounded-full cursor-pointer w-full p-2 text-white"
                        onClick={handelChatBlock}
                      >
                          Chat with Doctor
                      </div>
                    </div>
                  </div>
                )}
              </section>
            </div>
          </main>
          {medicalRecordScreen && (
            <MedicalRecord
              closeFunction={handelMedicalRecordScreen}
              data={data}
            />
          )}
        </div>
      </section>
      {chatBlock && (
        <HospitalChat
          closeFunction={handelChatBlock}
          doctorDetails={data?.AcceptedDoctor}
          consultationId={currentdata}
        />
      )}
    </>
  );
}

export default ConsultationViewRequest;
