import React, { useState } from 'react';
import MessageCard from '../../components/MessageNotification/MessageCard';
import { EditProfileLoader } from '../../components/common/loader';

function SideMessageNotification({
  data,
  profileData,
  setcurrentrequest,
  viewFunction,
  notificationfun,
}) {


  const allMsgBlock = data?.allNotification?.map((data, i) => {
    return (
      <MessageCard
        data={data}
        notificationfun={notificationfun}
        viewFunction={viewFunction}
        setcurrentrequest={setcurrentrequest}
        profileData={profileData}
        message={data.message}
        scanMessage={data.scanMessage}
        fromDate={data.fromDate}
        time={data.time}
        key={i}
      />
    );
  });
  const readMsgBlock = data?.readNotification?.map((data, i) => {
    return (
      <MessageCard
        data={data}
        notificationfun={notificationfun}
        viewFunction={viewFunction}
        setcurrentrequest={setcurrentrequest}
        profileData={profileData}
        message={data.message}
        scanMessage={data.scanMessage}
        fromDate={data.fromDate}
        time={data.time}
        key={i}
      />
    );
  });
  const unreadMsgBlock = data?.unReadNotification?.map((data, i) => {
    return (
      <MessageCard
        data={data}
        notificationfun={notificationfun}
        viewFunction={viewFunction}
        setcurrentrequest={setcurrentrequest}
        profileData={profileData}
        message={data.message}
        scanMessage={data.scanMessage}
        fromDate={data.fromDate}
        time={data.time}
        key={i}
      />
    );
  });

  const [tab, setTab] = useState(1);
  const handelTabs = (prop) => {
    setTab(prop);
  };
  return (
    <section className="h-full w-full">
      <div className="flex justify-between items-center">
        <h2 className="text-coalBlack subHeadingText">Notifications</h2>
        <div className="w-4 h-4 bg-green-600 rounded-full"></div>
      </div>
      <div className="flex  items-center gap-4 mt-5 p-4 border-b border-gray-200">
        <p
          className={`baseText cursor-pointer capitalize border-b-[3px]  hover:text-accent  ${
            tab == 1
              ? 'border-accent text-accent'
              : 'text-navLink border-transparent'
          }`}
          onClick={() => {
            handelTabs(1);
          }}
        >
          All
        </p>
        <p
          className={`baseText cursor-pointer capitalize border-b-[3px]   hover:text-accent ${
            tab == 2
              ? 'border-accent text-accent'
              : 'text-navLink border-transparent'
          }`}
          onClick={() => {
            handelTabs(2);
          }}
        >
          Read
        </p>
        <p
          className={`baseText cursor-pointer capitalize border-b-[3px]   hover:text-accent ${
            tab == 3
              ? 'border-accent text-accent'
              : 'text-navLink border-transparent'
          }`}
          onClick={() => {
            handelTabs(3);
          }}
        >
          Unread
        </p>
      </div>
      <section className="AllSection  space-y-3 py-8 pb-1 h-full max-h-[78%] overflow-y-auto pe-1 scrollbar hidden xl:block">
        {
          data ? (
            <>
              {tab === 1 ? (
                data?.allNotification?.length > 0 ? (
                  allMsgBlock
                ) : (
                  <p className="text-navLink baseText text-center">
              There are no notifications for me to display at the moment.
                  </p>
                )
              ) : null}
              {tab === 2 ? (
                data?.readNotification?.length > 0 ? (
                  readMsgBlock
                ) : (
                  <p className="text-navLink baseText text-center">
              There are no notifications for me to display at the moment.
                  </p>
                )
              ) : null}
              {tab === 3 ? (
                data?.unReadNotification?.length > 0 ? (
                  unreadMsgBlock
                ) : (
                  <p className="text-navLink baseText text-center">
              There are no notifications for me to display at the moment.
                  </p>
                )
              ) : null}
            </>
          ) : (
            <div className="w-full flex item-center justify-center mt-5">
              <EditProfileLoader />
            </div>
            
          )
        }
        
      </section>
    </section>
  );
}

export default SideMessageNotification;
