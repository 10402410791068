import React, { useContext, useEffect, useState } from 'react';
import ConsultationCard from '../../components/ConsultationCard/ConsultationCard';
import ProfileTopNavigation from './ProfileTopNavigation';
import { getAppointmentHistory, getUserApi } from '../../apis';
import { ProfileSliderContext } from '../../appContext';
function AppointmentHistory() {
  const [appointmentHistoryData, setAppointmentHistoryData] = useState(null);
  const [profileData, setprofileData] = useState(null);
  const [slider, setSlider, handelViewRequestBlock] = useContext(
    ProfileSliderContext
  );
  const [filterHistory, setFilterHistory] = useState('yearly'); //weekly  monthly  yearly
  const [doctorProfileId, setDoctorProfileId] = useState(null);

  const toGetAppointmentHistory = async (id) => {
    try {
      const res = await getAppointmentHistory(id);
      console.log(res);
      if (res.status === 200) {
        console.log(res.data.AppointmentHistory);
        setAppointmentHistoryData(res.data?.AppointmentHistory);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchHospital = async (id) => {
    try {
      const res = await getUserApi(id);
      if (res.status === 200) {
        setprofileData(res?.data?.Profile);
        toGetAppointmentHistory(res?.data?.Profile?.hospitalProfile?._id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchHospital(localStorage.getItem('userId'));
  }, []);
  const handleHistoryFilter = (event) => {
    const value = event.target.value;
    toGetAppointmentHistory(
      `${profileData.hospitalProfile?._id}&filter=${value}`
    );
    setFilterHistory(value);
  };
  let appointHistoryBlock;
  // if (appointmentHistoryData.length === 0) {
  //   return <p>No consultation found</p>;
  // }
  appointHistoryBlock = appointmentHistoryData?.map((data, key) => {
    console.log('ttt');
    console.log(data);
    return (
      <div key={key}>
        <ConsultationCard
          fillBtnTitle={'View Detail'}
          data={data}
          ViewRequest={() => {
            handelViewRequestBlock(data, profileData);
          }}
          fillBtnfun={() => {
            handelViewRequestBlock(data, profileData);
          }}
          showDeleteBtn={false}
        />
      </div>
    );
  });
  return (
    <>
      <div className="flex items-center flex-wrap gap-4 my-5 ">
        <ProfileTopNavigation pageTitle={'Appointment History'} />
        <select
          name="report"
          className=" bg-accent text-white px-4 py-2 rounded-[4px] min-w-[184px] capitalize w-full lg:w-auto lg:ms-auto"
          onChange={handleHistoryFilter}
          value={filterHistory}
        >
          <option
            className="text-coalBlack bg-white baseText  whitespace-nowrap"
            value={'weekly'}
          >
            {' '}
            weekly report
          </option>
          <option
            className="text-coalBlack bg-white baseText  whitespace-nowrap"
            value={'monthly'}
          >
            monthly report
          </option>
          <option
            className="text-coalBlack bg-white baseText  whitespace-nowrap"
            value={'yearly'}
          >
            yearly report
          </option>
        </select>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2  gap-4 pb-[25%]">
        {appointmentHistoryData?.length != 0 ? (
          appointmentHistoryData?.map((data, key) => (
            // No Consultations found.
            <div key={key}>
              <ConsultationCard
                fillBtnTitle={'View Detail'}
                data={data}
                ViewRequest={() => {
                  handelViewRequestBlock(data, profileData);
                }}
                fillBtnfun={() => {
                  handelViewRequestBlock(data, profileData);
                }}
                showDeleteBtn={false}
              />
            </div>
          ))
        ) : (
          <p className="text-center lg:col-span-2 capitalize baseText text-navLink  py-10">
            No consultations found
          </p>
        )}
      </div>
    </>
  );
}

export default AppointmentHistory;
