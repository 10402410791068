import React, { useContext } from 'react';
import ProfileTopNavigation from './ProfileTopNavigation';
import SimpleButton from '../../components/common/buttons/SimpleButton/SimpleButton';
import { AppContext } from '../../appContext';

function Logout() {
  const { logout } = useContext(AppContext);
  return (
    <div className="text-coalBlack">
      <ProfileTopNavigation pageTitle={'Logout account'} />
      <section className="my-8 max-w-md">
        <div>
          <img
            src={require('../../images/logout.png')}
            className="max-w-xs object-contain"
          />
        </div>
        <div className="my-2 baseText !font-medium max-w-xs">
             Are you sure you want to log out?{' '}
        </div>
        <p className="max-w-md text-navLink baseText">
          Don’t worry, everything you did will still be there when you log back in!
        </p>
        <div className='mt-20'>
          <SimpleButton
            customClass={'bg-accent text-white rounded-lg w-full'}
            buttonType={'primary'}
            title={'Yes, log me out!'}
            onClickEvent={() => {
              logout();
            }}
          />
        </div>
      </section>
    </div>
  );
}

export default Logout;
