import React, { useState, useContext, useEffect, useCallback } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { AiOutlineUser } from 'react-icons/ai';
import { IoVideocam } from 'react-icons/io5';
import { FaTags } from 'react-icons/fa';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import { InputErrorMessage } from '../errorMessages';

import { EditProfileLoader } from '../common/loader';

import {
  fileUploadApi,
  getCreditBasePrice,
  getSpecialization,
  getSpecializationDoctorList,
  getUserApi,
  getdoctorslistAPI,
  insertconsultationAPI,
  updateconsultationAPI,
} from '../../apis';
import Select from 'react-select';
import RecordSelector from './RecordsSelect';
import ProgressBar from 'react-bootstrap/esm/ProgressBar';
import { IoClose } from 'react-icons/io5';
import { AppContext } from '../../appContext';
import { useNavigate } from 'react-router-dom';
import { BsCurrencyRupee } from 'react-icons/bs';

function SelectType({
  formik,
  creditErrorBlock,
  setCreditErrorBlock,
  btnLoader,
}) {
  const navigate = useNavigate();
  const { loggedInUser } = useContext(AppContext);
  const [creditDetails, setCreditDetails] = useState(null);
  const [specialization, setSpecialization] = useState([]);
  const [listOfDoctor, setListOfDoctor] = useState([]);
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const [selectedOption, setSelectedOption] = useState(
    formik.values?.specialization != ''
      ? formik.values.chosenSpecialization
      : 'Select a specialization'
  );
  const [selectedDoctor, setSelectedDoctor] = useState(
    formik.values?.doctorList[0]
  );

  const specializationOption = specialization?.map((data) => {
    return {
      label: capitalizeFirstLetter(data?.name),
      value: data?._id,
      noOfCredit: data?.Amount,
    };
  });
  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    setFieldError,
  } = formik;
  const GetSpecialization = async () => {
    try {
      const res = await getSpecialization();
      if (res.status === 200) {
        setSpecialization(res.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const toGetCreditBasePrice = async () => {
    try {
      const res = await getCreditBasePrice();
      if (res.status === 200) {
        setCreditDetails(res?.data?.credit[0]);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  const handleConsultationType = (e) => {
    e.preventDefault();
    console.log(e.target.value);
    updateConsultationCost(e.target.value);
    formik.handleChange(e);
  };
  const handleSelectedDoctorList = (e) => {
    setSelectedDoctor(e);
    formik.setFieldValue('doctorList', [e]);
  };
  const updateSpecializationCost = (eventType, specializationCredit) => {
    setCreditErrorBlock((pre) => ({
      ...pre,
      specializationCost: specializationCredit,
      status: false,
      consultationCost:
        formik.values.consultationType === 'inperson'
          ? creditDetails?.InpersonConsultationCost
          : formik.values.consultationType === 'Virtual'
            ? creditDetails?.VirtualConsultationCost
            : null,
    }));
    if (specializationCredit === '') {
      formik.setFieldTouched('specialization', true);
    } else {
      formik.setFieldTouched('specialization', false);
    }
  };
  const updateConsultationCost = (eventType) => {
    setCreditErrorBlock((pre) => ({
      ...pre,
      consultationCost:
        eventType === 'inperson'
          ? creditDetails?.InpersonConsultationCost
          : eventType === 'Virtual'
            ? creditDetails?.VirtualConsultationCost
            : null,
    }));
    if (formik.values?.specializationCredit === '') {
      formik.setFieldTouched('specialization', true);
    } else {
      formik.setFieldTouched('specialization', false);
    }
  };
  const handleOption = (option) => {
    setSelectedOption(option);
    formik.setFieldValue('chosenSpecialization', option.label);
    formik.setFieldValue('specialization', option.value);
    formik.setFieldValue('specializationCredit', option.noOfCredit);
    updateSpecializationCost(formik.values.consultationType, option.noOfCredit);
    // toGetSpecializedDoctorList(option.value || null);
    // setSelectedDoctor(null);
    // formik.setFieldValue('doctorList', []);
  };
  useEffect(() => {
    toGetCreditBasePrice();
    GetSpecialization();
  }, []);
  const options = listOfDoctor.map((doctor) => ({
    value: doctor._id,
    label: doctor.DoctorName,
    image: doctor.profile,
    specialization: doctor.specialization[0] || null,
  }));

  const formatOptionLabel = ({ label, image, specialization }) => (
    <div className="doctor-option flex gap-x-2 items-center my-1 ">
      {image ? (
        <img
          src={image}
          alt={label}
          loading="lazy"
          className="size-9 rounded-full min-w-9 object-cover"
        />
      ) : (
        <div className="size-9 rounded-full min-w-9 bg-green-400 flex justify-center items-center">
          {label && label.slice(0,2)?.toUpperCase()}
        </div>
      )}
      <div className="shrink capitalize space-y-1">
        <p className="subHeadingText text-coalBlack line-clamp-1">
          Dr {label}
        </p>
        <p className="text-[10px] leading-3 shrink capitalize text-coalBlack line-clamp-1">
          {specialization}
        </p>
      </div>
    </div>
  );
  // console.log(selectedDoctor);
  // console.log(formik.values.doctorList);
  // console.log(formik.values.broadcastType);
  const toGetSpecializedDoctorList = async (id) => {
    try {
      const res = await getSpecializationDoctorList(id);
      if (res.status === 200) {
        setListOfDoctor(res?.data?.DoctorList);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    toGetSpecializedDoctorList(formik.values.specialization || null);
    setSelectedDoctor(null);
    formik.setFieldValue('doctorList', []);
  }, [selectedOption]);
  useEffect(() => {
    setSelectedDoctor(formik.values?.doctorList[0] || null);
    formik.setFieldValue('doctorList', []);
  }, []);
  return (
    <form
      className="NewRequestForm space-y-3.5 mt-6 text-coalBlack text-base bg-white"
      onSubmit={handleSubmit}
    >
      <div className="relative space-y-2.5 text-base font-medium consultationType">
        <p className="text-coalBlack">Select Consultation Type</p>
        <div className="specialization relative">
          <div className="">
            <Select
              name="specialization"
              placeholder={selectedOption}
              classNamePrefix=" specializationSelect"
              options={specializationOption}
              value={selectedOption}
              onChange={handleOption}
            />
          </div>
          <InputErrorMessage
            error={
              formik.touched.specialization && formik.errors.specialization
            }
          />
        </div>
        <div className="broadcast flex gap-x-2 flex-wrap">
          <div className="flex items-center gap-1">
            <input
              type="radio"
              name="broadcastType"
              id="public"
              value="public"
              checked={formik.values.broadcastType == 'public'}
              onChange={handleChange}
            />
            <label htmlFor="public">Public Broadcast</label>
          </div>
          <div className="flex items-center gap-1">
            <input
              type="radio"
              name="broadcastType"
              id="private"
              value="private"
              checked={formik.values.broadcastType == 'private'}
              onChange={handleChange}
            />
            <label htmlFor="private">Private Broadcast</label>
          </div>
        </div>
        {formik.values.broadcastType === 'private' && (
          <div className="doctorList relative">
            <div className="">
              <Select
                name="doctorList"
                placeholder={'Select the doctor'}
                classNamePrefix="specializationSelect"
                className="!bg-white"
                options={options}
                formatOptionLabel={formatOptionLabel}
                defaultValue={selectedDoctor}
                value={selectedDoctor}
                onChange={(e) => handleSelectedDoctorList(e)}
                noOptionsMessage={() => 'No doctor found!'}
              />
            </div>
            <InputErrorMessage
              error={formik.touched.doctorList && formik.errors.doctorList}
            />
          </div>
        )}
        <div className="bg-white p-1 rounded-2xl flex items-center justify-evenly shadow-blue_dropshadow">
          <input
            type="radio"
            name="consultationType"
            id="inperson"
            value="inperson"
            onChange={handleConsultationType}
            checked={formik.values.consultationType === 'inperson'}
            hidden
          />
          <label htmlFor="inperson" className="w-full cursor-pointer">
            <div
              className={`flex items-center gap-1 justify-center capitalize px-3 py-2 rounded-xl flex-grow ${
                formik.values.consultationType === 'inperson'
                  ? 'bg-accent text-white'
                  : 'text-gray-400'
              }`}
            >
              <AiOutlineUser className="text-2xl text-inherit" />
              <span className="text-center">In-Person</span>
            </div>
          </label>
          <input
            type="radio"
            name="consultationType"
            id="Virtual"
            value="Virtual"
            onChange={handleConsultationType}
            checked={formik.values.consultationType === 'Virtual'}
            hidden
          />
          <label htmlFor="Virtual" className="w-full cursor-pointer">
            <div
              className={`flex items-center gap-1 justify-center capitalize px-3 py-2 rounded-xl flex-grow ${
                formik.values.consultationType === 'Virtual'
                  ? 'bg-accent text-white'
                  : 'text-gray-400'
              }`}
            >
              <IoVideocam className="text-2xl text-inherit" />
              <span className=" text-center">Virtual</span>
            </div>
          </label>
        </div>
        <div className="relative consultationPriority">
          <p className="text-coalBlack capitalize mt-6">
            consultation Priority
          </p>
          <div className="bg-white p-1 rounded-2xl flex items-center justify-evenly shadow-blue_dropshadow">
            <input
              type="radio"
              name="consultationPriority"
              id="urgent"
              value="urgent"
              onChange={handleChange}
              checked={formik.values.consultationPriority === 'urgent'}
              hidden
            />
            <label htmlFor="urgent" className="w-full cursor-pointer">
              <div
                className={`capitalize px-3 py-2 rounded-xl flex-grow text-center ${
                  formik.values.consultationPriority === 'urgent'
                    ? 'bg-[#FC5555] text-white'
                    : 'text-gray-400'
                }`}
              >
                Urgent
              </div>
            </label>
            <input
              type="radio"
              name="consultationPriority"
              id="regular"
              value="regular"
              onChange={handleChange}
              checked={formik.values.consultationPriority === 'regular'}
              hidden
            />
            <label htmlFor="regular" className="w-full cursor-pointer">
              <div
                className={`capitalize px-3 py-2 rounded-xl flex-grow text-center ${
                  formik.values.consultationPriority === 'regular'
                    ? 'bg-green-400 text-white'
                    : 'text-gray-400'
                }`}
              >
                Routine
              </div>
            </label>
          </div>
          <InputErrorMessage
            error={
              formik.touched.consultationPriority &&
              formik.errors.consultationPriority
            }
          />
        </div>
        <InputErrorMessage
          error={
            formik.touched.consultationType && formik.errors.consultationType
          }
        />
      </div>

      <div className="relative space-y-4 bg-white shadow-blue_dropshadow px-2 py-4 rounded-lg Exp">
        <div className="flex items-center gap-2">
          <div className="w-9 h-9 rounded-full bg-orange-100 flex justify-center items-center">
            <FaTags className="text-2xl text-orange-500 p-1" />
          </div>
          <p className="font-medium">Appointment booking cost</p>
        </div>
        <div className="flex items-center gap-2 p-2 rounded-[20px] bg-sky-50 flex-col">
          <div className="w-full text-[#333] font-medium flex justify-between items-center px-3 py-1">
            <span>Specialization cost</span>
            <span className="flex items-center">
              <BsCurrencyRupee /> {creditErrorBlock?.specializationCost || 0}
            </span>
          </div>
          <div className="w-full text-[#333] font-medium flex justify-between items-center px-3 py-1">
            <span>Consultation type</span>
            <span className="flex items-center">
              <BsCurrencyRupee /> {creditErrorBlock?.consultationCost || 0}
            </span>
          </div>
          <div className="w-full text-[#333] font-medium flex justify-between items-center px-3 py-1">
            <span>Total</span>
            <span className="flex items-center">
              <BsCurrencyRupee />{' '}
              {creditErrorBlock?.consultationCost +
                creditErrorBlock?.specializationCost}
            </span>
          </div>
        </div>
      </div>
      <div className="py-10 submitBtn pb-24 lg:hidden">
        <button
          className="btn btn_primary_black text-white w-full rounded-xl"
          type="submit"
        >
          {!btnLoader ? (
            'Continue'
          ) : (
            <div className="text-xs">
              <EditProfileLoader />
            </div>
          )}
        </button>
      </div>
    </form>
  );
}

export default SelectType;
