import React, { useState, useContext, useEffect, useCallback } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { AiOutlineUser } from 'react-icons/ai';
import { IoVideocam } from 'react-icons/io5';
import { FaTags } from 'react-icons/fa';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import { InputErrorMessage } from '../errorMessages';
import { ConsultationRequest } from '../Validation';
import { HiOutlinePlus } from 'react-icons/hi2';
import { HiOutlineX } from 'react-icons/hi';
import { BiSolidReport } from 'react-icons/bi';
import { FaFilePrescription } from 'react-icons/fa';
import { EditProfileLoader } from '../common/loader';
import { GrNotes } from 'react-icons/gr';
import { FaLocationDot } from 'react-icons/fa6';
import { IoMdCall } from 'react-icons/io';
import { useDropzone } from 'react-dropzone';
import { CloudArrowUpFill, X } from 'react-bootstrap-icons';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import {
  fileUploadApi,
  getCreditBasePrice,
  getSpecialization,
  getUserApi,
  getdoctorslistAPI,
  insertconsultationAPI,
  updateconsultationAPI,
} from '../../apis';
import Select from 'react-select';
import RecordSelector from './RecordsSelect';
import ProgressBar from 'react-bootstrap/esm/ProgressBar';
import { IoClose } from 'react-icons/io5';
import { AppContext } from '../../appContext';
import { useNavigate } from 'react-router-dom';
import { BsCurrencyRupee } from 'react-icons/bs';

function SelectType({ formik, creditErrorBlock, setCreditErrorBlock,btnLoader }) {
  const navigate = useNavigate();
  const { loggedInUser } = useContext(AppContext);
  const [creditDetails, setCreditDetails] = useState(null);
  const [specialization, setSpecialization] = useState([]);
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  useEffect(() => {
    console.log('specialization');
    console.log(specialization);
  }, [specialization]);
  // const [loader, setLoader] = useState(false);

  const [selectedOption, setSelectedOption] = useState(
    formik.values?.specialization != ''
      ? formik.values.chosenSpecialization
      : 'Select a specialization'
  );
  const specializationOption = specialization?.map((data) => {
    return {
      label: capitalizeFirstLetter(data?.name),
      value: data?._id,
      noOfCredit: data?.Amount,
    };
  });
  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    setFieldError,
  } = formik;
  const GetSpecialization = async () => {
    try {
      const res = await getSpecialization();
      if (res.status === 200) {
        setSpecialization(res.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const toGetCreditBasePrice = async () => {
    try {
      const res = await getCreditBasePrice();
      if (res.status === 200) {
        setCreditDetails(res?.data?.credit[0]);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  const handleConsultationType = (e) => {
    e.preventDefault();
    console.log(e.target.value);
    updateConsultationCost(e.target.value);
    formik.handleChange(e);
  };
  const updateSpecializationCost = (eventType, specializationCredit) => {
    setCreditErrorBlock((pre) => ({
      ...pre,
      specializationCost: specializationCredit,
      status: false,
      consultationCost:
        formik.values.consultationType === 'inperson'
          ? creditDetails?.InpersonConsultationCost
          : formik.values.consultationType === 'Virtual'
            ? creditDetails?.VirtualConsultationCost
            : null,
    }));
    if (specializationCredit === '') {
      formik.setFieldTouched('specialization', true);
    } else {
      formik.setFieldTouched('specialization', false);
    }
  };
  const updateConsultationCost = (eventType) => {
    setCreditErrorBlock((pre) => ({
      ...pre,
      consultationCost:
        eventType === 'inperson'
          ? creditDetails?.InpersonConsultationCost
          : eventType === 'Virtual'
            ? creditDetails?.VirtualConsultationCost
            : null,
    }));
    if (formik.values?.specializationCredit === '') {
      formik.setFieldTouched('specialization', true);
    } else {
      formik.setFieldTouched('specialization', false);
    }
  };
  const handleOption = (option) => {
    setSelectedOption(option);
    formik.setFieldValue('chosenSpecialization', option.label);
    formik.setFieldValue('specialization', option.value);
    formik.setFieldValue('specializationCredit', option.noOfCredit);
    updateSpecializationCost(formik.values.consultationType, option.noOfCredit);
  };
  useEffect(() => {
    toGetCreditBasePrice();
    GetSpecialization();
  }, []);
  return (
    <form
      className="NewRequestForm space-y-3.5 mt-6 text-coalBlack text-base bg-white"
      onSubmit={handleSubmit}
    >
      <div className="relative space-y-2.5 text-base font-medium consultationType">
        <p className="text-coalBlack">Select Consultation Type</p>
        <div className="specialization relative">
          <div className="">
            <Select
              name="specialization"
              placeholder={selectedOption}
              classNamePrefix=" specializationSelect"
              options={specializationOption}
              value={selectedOption}
              onChange={handleOption}
            />
          </div>
          <InputErrorMessage
            error={
              formik.touched.specialization && formik.errors.specialization
            }
          />
        </div>
        <div className="bg-white p-1 rounded-2xl flex items-center justify-evenly shadow-blue_dropshadow">
          <input
            type="radio"
            name="consultationType"
            id="inperson"
            value="inperson"
            onChange={handleConsultationType}
            checked={formik.values.consultationType === 'inperson'}
            hidden
          />
          <label htmlFor="inperson" className="w-full cursor-pointer">
            <div
              className={`flex items-center gap-1 justify-center capitalize px-3 py-2 rounded-xl flex-grow ${
                formik.values.consultationType === 'inperson'
                  ? 'bg-accent text-white'
                  : 'text-gray-400'
              }`}
            >
              <AiOutlineUser className="text-2xl text-inherit" />
              <span className="text-center">In-Person</span>
            </div>
          </label>
          <input
            type="radio"
            name="consultationType"
            id="Virtual"
            value="Virtual"
            onChange={handleConsultationType}
            checked={formik.values.consultationType === 'Virtual'}
            hidden
          />
          <label htmlFor="Virtual" className="w-full cursor-pointer">
            <div
              className={`flex items-center gap-1 justify-center capitalize px-3 py-2 rounded-xl flex-grow ${
                formik.values.consultationType === 'Virtual'
                  ? 'bg-accent text-white'
                  : 'text-gray-400'
              }`}
            >
              <IoVideocam className="text-2xl text-inherit" />
              <span className=" text-center">Virtual</span>
            </div>
          </label>
        </div>
        <div className="relative consultationPriority">
          <p className="text-coalBlack capitalize mt-6">
            consultation Priority
          </p>
          <div className="bg-white p-1 rounded-2xl flex items-center justify-evenly shadow-blue_dropshadow">
            <input
              type="radio"
              name="consultationPriority"
              id="urgent"
              value="urgent"
              onChange={handleChange}
              checked={formik.values.consultationPriority === 'urgent'}
              hidden
            />
            <label htmlFor="urgent" className="w-full cursor-pointer">
              <div
                className={`capitalize px-3 py-2 rounded-xl flex-grow text-center ${
                  formik.values.consultationPriority === 'urgent'
                    ? 'bg-[#FC5555] text-white'
                    : 'text-gray-400'
                }`}
              >
                Urgent
              </div>
            </label>
            <input
              type="radio"
              name="consultationPriority"
              id="regular"
              value="regular"
              onChange={handleChange}
              checked={formik.values.consultationPriority === 'regular'}
              hidden
            />
            <label htmlFor="regular" className="w-full cursor-pointer">
              <div
                className={`capitalize px-3 py-2 rounded-xl flex-grow text-center ${
                  formik.values.consultationPriority === 'regular'
                    ? 'bg-green-400 text-white'
                    : 'text-gray-400'
                }`}
              >
                Routine
              </div>
            </label>
          </div>
          <InputErrorMessage
            error={
              formik.touched.consultationPriority &&
              formik.errors.consultationPriority
            }
          />
        </div>
        <InputErrorMessage
          error={
            formik.touched.consultationType && formik.errors.consultationType
          }
        />
        {/* {creditErrorBlock.status && (
          <div className="creditErrorBlock !mt-0  relative shadow-lg rounded-lg">
            <div className="bg-red-100 rounded-lg p-2 border-4 border-red-200 relative flex items-center justify-between gap-3">
              <p className="text-sm font-medium text-red-600">
                You don't have enough credit point to create a consultation
              </p>
              <button
                className="px-2 py-1 text-xs font-semibold bg-accent text-white rounded-[4px] whitespace-nowrap"
                onClick={() => {
                  navigate('/wallet');
                }}
              >
                Buy Now
              </button>
            </div>
            <div className="absolute -top-11 left-4 size-11 overflow-hidden flex justify-center items-center">
              <div className="size-8 bg-gray-100 rotate-45 relative top-8"></div>
            </div>
          </div>
        )} */}
      </div>

      <div className="relative space-y-4 bg-white shadow-blue_dropshadow px-2 py-4 rounded-lg Exp">
        <div className="flex items-center gap-2">
          <div className="w-9 h-9 rounded-full bg-orange-100 flex justify-center items-center">
            <FaTags className="text-2xl text-orange-500 p-1" />
          </div>
          <p className="font-medium">Appointment booking cost</p>
        </div>
        <div className="flex items-center gap-2 p-2 rounded-[20px] bg-sky-50 flex-col">
          <div className="w-full text-[#333] font-medium flex justify-between items-center px-3 py-1">
            <span>Specialization cost</span>
            <span className="flex items-center">
              <BsCurrencyRupee /> {creditErrorBlock?.specializationCost || 0}
            </span>
          </div>
          <div className="w-full text-[#333] font-medium flex justify-between items-center px-3 py-1">
            <span>Consultation type</span>
            <span className="flex items-center">
              <BsCurrencyRupee /> {creditErrorBlock?.consultationCost || 0}
            </span>
          </div>
          <div className="w-full text-[#333] font-medium flex justify-between items-center px-3 py-1">
            <span>Total</span>
            <span className="flex items-center">
              <BsCurrencyRupee />{' '}
              {creditErrorBlock?.consultationCost +
                creditErrorBlock?.specializationCost}
            </span>
          </div>
        </div>
      </div>
      <div className="py-10 submitBtn pb-24 lg:hidden">
        <button
          className="btn btn_primary_black text-white w-full rounded-xl"
          type="submit"
        >
          {!btnLoader ? (
            'Continue'
          ) : (
            <div className="text-xs">
              <EditProfileLoader />
            </div>
          )}
        </button>
      </div>
    </form>
  );
}

export default SelectType;
